import PropTypes from 'prop-types';
import React from 'react';

import Box from './Box';
import Icon from './Icon';
import Text from './Text';

// eslint-disable-next-line
const LikeButton = ({ liked, likes, ...props }) => (
  <Box flexShrink="0" id="likeButton" paddingX="medium" position="absolute" right="0px" {...props}>
    <Icon type="heart" color="white" size="small" />
    <Text color="white" fontSize="xSmall" paddingLeft="small">
      {likes}
    </Text>
  </Box>
);

LikeButton.propTypes = {
  liked: PropTypes.bool,
  likes: PropTypes.number,
};

LikeButton.defaultProps = {
  liked: false,
  likes: 0,
};

export default LikeButton;
