// src/functions/styleFunctions.js

import { spaces } from '../constants';

// big hover box shadow
export const bigBoxShadow = () => 'box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2)';

// regular box shadow
export const boxShadow = () => 'box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1)';

export const sideBoxShadow = () => 'box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1)';

// inset box shadow
export const insetBoxShadow = () => 'box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1) inset';

// button shadow and transition with focus and active states
export const buttonShadow = `
  ${boxShadow()};
  &:hover {
    ${bigBoxShadow()};
  }
  &:active {
    ${insetBoxShadow()};
  }
  &:visited {
    box-shadow: none;
  }
`;

const marginStyle = m =>
  m
    .split(' ')
    .map(part => spaces[part] || part)
    .join(' ');

const margin = ({
  margin: m,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  marginX,
  marginY
}) => {
  let styles = {};
  if (m)
    styles = {
      ...styles,
      margin: marginStyle(m)
    };
  if (marginX)
    styles = {
      ...styles,
      marginLeft: spaces[marginX] || marginX,
      marginRight: spaces[marginX] || marginX
    };
  if (marginY)
    styles = {
      ...styles,
      marginBottom: spaces[marginY] || marginY,
      marginTop: spaces[marginY] || marginY
    };
  if (marginBottom)
    styles = {
      ...styles,
      marginBottom: spaces[marginBottom] || marginBottom
    };
  if (marginLeft)
    styles = {
      ...styles,
      marginLeft: spaces[marginLeft] || marginLeft
    };
  if (marginRight)
    styles = {
      ...styles,
      marginRight: spaces[marginRight] || marginRight
    };
  if (marginTop)
    styles = {
      ...styles,
      marginTop: spaces[marginTop] || marginTop
    };
  return styles;
};

const paddingStyle = p =>
  p
    .split(' ')
    .map(part => spaces[part] || part)
    .join(' ');

const padding = ({
  padding: p,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingX,
  paddingY
}) => {
  let styles = {};
  if (p)
    styles = {
      ...styles,
      padding: paddingStyle(p)
    };
  if (paddingX)
    styles = {
      ...styles,
      paddingLeft: spaces[paddingX] || paddingX,
      paddingRight: spaces[paddingX] || paddingX
    };
  if (paddingY)
    styles = {
      ...styles,
      paddingBottom: spaces[paddingY] || paddingY,
      paddingTop: spaces[paddingY] || paddingY
    };
  if (paddingBottom)
    styles = {
      ...styles,
      paddingBottom: spaces[paddingBottom] || paddingBottom
    };
  if (paddingLeft)
    styles = {
      ...styles,
      paddingLeft: spaces[paddingLeft] || paddingLeft
    };
  if (paddingRight)
    styles = {
      ...styles,
      paddingRight: spaces[paddingRight] || paddingRight
    };
  if (paddingTop)
    styles = {
      ...styles,
      paddingTop: spaces[paddingTop] || paddingTop
    };
  return styles;
};

export { margin, padding };
