import initialState from './initialState'
import ActionTypes from '../actions/actionTypes'

const newAdminPromptReducer = (state = initialState.newAdminPrompt, action) => {
  switch(action.type) {

    case ActionTypes.CLEAR_NEW_ADMIN_PROMPT:
      return initialState.newAdminPrompt

    case ActionTypes.CHANGE_NEW_ADMIN_PROMPT_TITLE:
      return { ...state, title: action.title }

    case ActionTypes.PICK_NEW_ADMIN_PROMPT_CATEGORY:
      const categories = state.categories.slice()
      const category = action.category
      const categoryIndex = categories.indexOf(category)
      categoryIndex === -1 ? categories.push(category) : categories.splice(categoryIndex, 1)
      return { ...state, categories: categories }

    default:
      return state
  }
}

export default newAdminPromptReducer
