// src/pages/App.js

import ObjectID from 'bson-objectid';
import Chart from 'chart.js';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import ReactChartkick from 'react-chartkick';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { loadAdmin } from './actions/adminActions';
import { loadSharedAnswers } from './actions/answerActions';
import { setError } from './actions/errorActions';
import { loadApprovedPrompts, loadTodayPrompt, loadUserPrompts } from './actions/promptActions';
import { closeSideMenu } from './actions/sideMenuActions';
import { clearStatus, setStatus } from './actions/statusActions';
import {
  createUser,
  loadCategories,
  loadUser,
  loadUserAnswers,
  resetSeen
} from './actions/userActions';
import SideMenu from './components/SideMenu';
import analytics from './functions/analyticsFunctions';
import { isToday } from './functions/dateFunctions';
import CodeModal from './modals/CodeModal';
import routes from './routes';
import './stylesheets/dropzoneStyles.css';
import './stylesheets/reactCodeStyles.css';
import './stylesheets/styles.css';
import './stylesheets/transitionsStyles.css';

ReactChartkick.addAdapter(Chart);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showCode: false,
      timeout: ''
    };

    this.clearTimeout = this.clearTimeout.bind(this);
    this.clearStatus = this.clearStatus.bind(this);
    this.handleConfirmCode = this.handleConfirmCode.bind(this);
    this.handleLoadToday = this.handleLoadToday.bind(this);
    this.handleLoadUser = this.handleLoadUser.bind(this);
  }

  componentDidMount() {
    let {
      closeSideMenu,
      createUser,
      history,
      loadAdmin,
      loadApprovedPrompts,
      loadCategories,
      loadSharedAnswers,
      resetSeen,
      seen,
      user
    } = this.props;
    const { _id, code } = user;

    analytics.init("13388d882bed89ae83677cbab707011f");

    loadAdmin();
    loadApprovedPrompts();
    loadCategories();
    loadSharedAnswers(1);
    closeSideMenu();

    if (!isToday(seen.today)) {
      resetSeen();
    }

    if (_id) {
      this.handleLoadUser(_id, code);
    } else {
      const newUser = {
        _id: ObjectID(),
        last: user.last
      };
      createUser(newUser);

      history.replace('/home');
    }

    document.addEventListener('visibilitychange', this.handleLoadToday);
  }

  componentWillReceiveProps(nextProps) {
    let { createUser, status, clearStatus, user: { _id } } = this.props;
    const { user: { _id: nextUserId, code } } = nextProps;

    if (!nextUserId) {
      const newUser = {
        _id: ObjectID(),
        last: nextProps.user.last
      };
      createUser(newUser);
    }

    if (_id !== nextUserId) {
      this.handleLoadUser(nextUserId, code);
    }

    if (!this.state.timeout && !nextProps.status.message) {
      return;
    } else if (this.state.timeout && !nextProps.status.message) {
      this.clearTimeout();
    } else if (this.state.timeout && status.message !== nextProps.status.message) {
      this.clearTimeout();
      const timeout = setTimeout(this.clearStatus, 2000);
      this.setState({ timeout: timeout });
    } else if (!this.state.timeout && status.message !== nextProps.status.message) {
      const timeout = setTimeout(this.clearStatus, 2000);
      this.setState({ timeout: timeout });
    } else if (!this.state.timeout && status.message === nextProps.status.message) {
      clearStatus();
    }
  }

  componentWillUnmount() {
    this.clearTimeout();

    document.removeEventListener('visibilitychange', this.handleLoadToday);
  }

  clearTimeout() {
    clearTimeout(this.state.timeout);
    this.setState({ timeout: '' });
  }

  clearStatus() {
    clearTimeout(this.state.timeout);
    this.setState({ timeout: '' });
    this.props.clearStatus();
  }

  handleConfirmCode(code) {
    const { setStatus, user } = this.props;
    if (code === user.code) {
      setStatus('Success', false);
      this.setState({ showCode: false });
    } else {
      this.props.setError('Passcode does not match');
    }
  }

  handleLoadUser(_id, code) {
    const {
      loadUser,
      loadUserAnswers,
      loadUserPrompts,
      user,
    } = this.props;

    loadUserPrompts(_id);
    loadUser(_id);
    loadUserAnswers(_id);

    analytics.identify(user._id);
    analytics.people.set({
      $email: user.email,
      $name: user.name,
      USER_ID: user._id,
      USER_EMAIL: user.email,
    });
    analytics.track('load user');

    if (code) this.setState({ showCode: true });
  }

  handleLoadToday() {
    const { loadTodayPrompt, user: { _id } } = this.props;
    if (!document.hidden) {
      loadTodayPrompt();
      if (_id) {
        this.handleLoadUser(_id, null);
      }
    }
  }

  render() {
    const { showCode } = this.state;

    return (
      <Fragment>
        <Helmet titleTemplate="Müse | %s" defaultTitle="Müse | Daily Reflection" />
        {showCode && (
          <CodeModal handleClose={this.handleCodeModal} noClose onFinish={this.handleConfirmCode} />
        )}
        <SideMenu />
        {routes}
      </Fragment>
    );
  }
}

App.propTypes = {
  answers: PropTypes.array.isRequired,
  clearStatus: PropTypes.func.isRequired,
  closeSideMenu: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  loadAdmin: PropTypes.func.isRequired,
  loadApprovedPrompts: PropTypes.func.isRequired,
  loadCategories: PropTypes.func.isRequired,
  loadSharedAnswers: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  loadUserAnswers: PropTypes.func.isRequired,
  loadUserPrompts: PropTypes.func.isRequired,
  loadTodayPrompt: PropTypes.func.isRequired,
  resetSeen: PropTypes.func.isRequired,
  seen: PropTypes.object.isRequired,
  setError: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  status: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = ({ user, answers, status, seen }) => ({
  user,
  answers,
  status,
  seen
});

App = connect(mapStateToProps, {
  clearStatus,
  closeSideMenu,
  createUser,
  loadAdmin,
  loadApprovedPrompts,
  loadCategories,
  loadSharedAnswers,
  loadUser,
  loadUserAnswers,
  loadUserPrompts,
  loadTodayPrompt,
  resetSeen,
  setError,
  setStatus
})(App);

export default withRouter(App);
