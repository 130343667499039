import PropTypes from 'prop-types';
import React from 'react';

import { alignments, colors, widths } from '../constants';
import { padding } from '../functions/styleFunctions';

const styles = ({
  alignItems,
  backgroundColor,
  flexDirection,
  justifyContent,
  height,
  zIndex,
  ...props
}) => ({
  body: {
    backgroundColor: colors[backgroundColor],
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: height,
    width: '100%',
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    zIndex: zIndex || 0
  },
  container: {
    alignItems: alignments[alignItems],
    position: 'relative',
    display: 'flex',
    ...padding(props),
    flexDirection,
    justifyContent: alignments[justifyContent],
    maxWidth: widths.contain,
    width: '100%',
    margin: '0px auto',
    height: '100%'
  }
});

const Container = ({
  alignItems,
  backgroundColor,
  children,
  flexDirection,
  height,
  justifyContent,
  padding,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingX,
  paddingY,
  zIndex,
  ...props
}) => {
  const style = styles({
    alignItems,
    backgroundColor,
    children,
    flexDirection,
    height,
    justifyContent,
    padding,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingX,
    paddingY,
    zIndex
  });

  return (
    <div style={style.body} {...props}>
      <div style={style.container}>{children}</div>
    </div>
  );
};

Container.propTypes = {
  alignItems: PropTypes.string,
  backgroundColor: PropTypes.string,
  children: PropTypes.any,
  flexDirection: PropTypes.string,
  height: PropTypes.string,
  justifyContent: PropTypes.string,
  padding: PropTypes.string,
  paddingBottom: PropTypes.string,
  paddingLeft: PropTypes.string,
  paddingRight: PropTypes.string,
  paddingTop: PropTypes.string,
  paddingX: PropTypes.any,
  paddingY: PropTypes.any,
  zIndex: PropTypes.number
};

Container.defaultProps = {
  alignItems: 'stretch',
  backgroundColor: 'white',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'flexStart'
};

export default Container;
