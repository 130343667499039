import React from 'react';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';

import AboutPage from './pages/AboutPage';
import AnswerPage from './pages/AnswerPage';
import CategoriesPage from './pages/CategoriesPage';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import NewPromptPage from './pages/NewPromptPage';
import NotFoundPage from './pages/NotFoundPage';
import UserProfilePage from './pages/UserProfilePage';
import WorldPage from './pages/WorldPage';

const AdminPage = loadable(() => import('./pages/AdminPage'));
const AnswerSettingsPage = loadable(() => import('./pages/AnswerSettingsPage'));
// const DataPage = loadable(() => import('./pages/DataPage'));
const EditPromptPage = loadable(() => import('./pages/EditPromptPage'));
const ManagePromptsPage = loadable(() => import('./pages/ManagePromptsPage'));
const NewAdminPromptPage = loadable(() => import('./pages/NewAdminPromptPage'));
const PastPage = loadable(() => import('./pages/PastPage'));
const PrivacyPage = loadable(() => import('./pages/PrivacyPage'));
const ReportedAnswersPage = loadable(() => import('./pages/ReportedAnswersPage'));
const SharedPage = loadable(() => import('./pages/SharedPage'));
const StopPage = loadable(() => import('./pages/StopPage'));
const StreakPage = loadable(() => import('./pages/StreakPage'));
const SubmittedPromptsPage = loadable(() => import('./pages/SubmittedPromptsPage'));
const TermsPage = loadable(() => import('./pages/TermsPage'));
const UserPage = loadable(() => import('./pages/UserPage'));
const UserSettingsPage = loadable(() => import('./pages/UserSettingsPage'));

export default (
  <Switch>
    <Route exact path="/" component={CategoriesPage} />
    <Route exact path="/home" component={HomePage} />
    <Route exact path="/answer" component={AnswerPage} />
    <Route exact path="/answer/today" component={AnswerPage} />
    <Route exact path="/answer/journal" component={AnswerPage} />
    <Route path="/answer/journal/:answerId" component={AnswerPage} />
    <Route path="/answer/:promptId" component={AnswerPage} />
    <Route exact path="/streak" component={StreakPage} />
    <Route exact path="/login" component={LoginPage} />
    <Route path="/user/:userId" component={UserPage} />
    <Route exact path="/userProfile" component={UserProfilePage} />
    <Route exact path="/userSettings" component={UserSettingsPage} />
    <Route exact path="/answerSettings" component={AnswerSettingsPage} />
    <Route exact path="/past" component={PastPage} />
    <Route exact path="/newPrompt" component={NewPromptPage} />
    <Route exact path="/managePrompts" component={ManagePromptsPage} />
    <Route exact path="/admin" component={AdminPage} />
    {/*<Route exact path="/data" component={DataPage} />*/}
    <Route exact path="/editPrompt" component={EditPromptPage} />
    <Route exact path="/newAdminPrompt" component={NewAdminPromptPage} />
    <Route exact path="/submittedPrompts" component={SubmittedPromptsPage} />
    <Route exact path="/privacy" component={PrivacyPage} />
    <Route exact path="/terms" component={TermsPage} />
    <Route exact path="/about" component={AboutPage} />
    <Route exact path="/reported" component={ReportedAnswersPage} />
    <Route exact path="/stop" component={StopPage} />
    <Route exact path="/shared" component={SharedPage} />
    <Route exact path="/world" component={WorldPage} />
    <Route path="*" component={NotFoundPage} />
  </Switch>
);
