import React from 'react';

import Button from './Button';

const ConfirmButton = props => <Button className="confirm-button" {...props} />;

ConfirmButton.defaultProps = {
  backgroundColor: 'red',
  color: 'white',
  flexBasis: '50%',
  flexShrink: 1,
  marginLeft: 'small'
};

export default ConfirmButton;
