import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Box from '../components/Box';
import Container from '../components/Container';
import Page from '../components/Page';
import Footer from '../components/Footer';
import FooterButton from '../components/FooterButton';
import Title from '../components/Title';

class NotFoundPage extends Component {
  handleBack() {
    this.props.history.push('/');
  }

  render() {
    return (
      <Page>
        <Container paddingX="medium">
          <Box
            flexDirection="column"
            flexGrow={1}
            flexShrink={1}
            alignItems="center"
            justifyContent="center"
          >
            <Title fontSize="32px" textAlign="center" paddingBottom="xLarge">
              You've found the secret to happiness!
            </Title>
            <Title textAlign="center" paddingBottom="xLarge" paddingTop="xLarge">
              Just kidding. The secret is found within each one of us. Keep doing your best
            </Title>
            <Title color="darkGrey" textAlign="center">
              This page was not found.
            </Title>
          </Box>
        </Container>
        <Footer>
          <FooterButton color="white" id="notFound" to="/" flexGrow={1} backgroundColor="purple">
            Back to müse
          </FooterButton>
        </Footer>
      </Page>
    );
  }
}

NotFoundPage.propTypes = {
  history: PropTypes.object.isRequired
};

export default NotFoundPage;
