import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const offlineReducer = (state = initialState.offline, action) => {
  switch (action.type) {
    case ActionTypes.OFFLINE_SAVE:
      return { ...state, beenOffline: false };

    case ActionTypes.OFFLINE_SAVE_SUCCESS:
      return initialState.offline;

    case ActionTypes.OFFLINE_SAVE_FAIL:
      // return { ...state, beenOffline: true }
      return state;

    case ActionTypes.SAVE_ANSWER_OFFLINE:
      let saveScore = state.score;
      let saveAnswered = [...state.answered];
      let saveAnswer = action.answer;
      saveAnswer.modified = new Date();
      let saveAnswerIndex = saveAnswered.findIndex(answer => answer._id === saveAnswer._id);
      let newSaveAnswered =
        saveAnswerIndex === -1
          ? [...saveAnswered, saveAnswer]
          : [
              ...saveAnswered.slice(0, saveAnswerIndex),
              saveAnswer,
              ...saveAnswered.slice(saveAnswerIndex + 1)
            ];

      let newSaveScore = saveScore + saveAnswer.score;

      return {
        ...state,
        beenOffline: true,
        answered: newSaveAnswered,
        score: newSaveScore,
      };

    case ActionTypes.DELETE_ANSWER_OFFLINE:
      return { ...state, beenOffline: true, deleted: [...state.deleted, action.answer_id] };

    case ActionTypes.SIGNUP_USER_SUCCESS:
      let signupAnswered = state.answered.slice();
      signupAnswered.forEach(answer => {
        answer.user_name = action.user.name;
      });
      return { ...state, answered: signupAnswered };

    case ActionTypes.LOGIN_USER_SUCCESS:
      let loginAnswered = state.answered.slice();
      loginAnswered.forEach(answer => {
        answer.user_id = action.user._id;
        answer.user_name = action.user.name;
      });
      return { ...state, answered: loginAnswered };

    case ActionTypes.CREATE_NEW_PROMPT_OFFLINE:
      return { ...state, beenOffline: true, created: [...state.created, action.prompt] };

    case ActionTypes.UPDATE_STREAK_OFFLINE:
      let updateUser = action.user;
      let streak = updateUser.streak + 1;
      let longest = updateUser.longestStreak > streak ? updateUser.longestStreak : streak;
      return {
        ...state,
        beenOffline: true,
        streak: streak,
        last: new Date(),
        longestStreak: longest
      };

    case ActionTypes.RESET_STREAK_OFFLINE:
      return { ...state, beenOffline: true, streak: 1, last: new Date() };

    default:
      return state;
  }
};

export default offlineReducer;
