import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Box from '../components/Box';
import CancelButton from '../components/CancelButton';
import ConfirmButton from '../components/ConfirmButton';
import Modal from '../components/Modal';
import Title from '../components/Title';

const ShareAsModal = ({ handleClose, onClick, user }) => (
  <Modal onClick={handleClose} padding="medium">
    <Title color="darkGrey" padding="medium" textAlign="center">
      Share as whom?
    </Title>
    <Box padding="medium">
      <CancelButton id="shareAnonymous" onClick={() => onClick(true)}>
        Anonymous
      </CancelButton>
      <ConfirmButton backgroundColor="purple" id="shareMe" onClick={() => onClick(false)}>
        {user.name}
      </ConfirmButton>
    </Box>
  </Modal>
);

ShareAsModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = ({ user }) => ({
  user
});

export default connect(mapStateToProps)(ShareAsModal);
