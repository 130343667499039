import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import * as answerActions from '../actions/answerActions';
import * as loginActions from '../actions/loginActions';
import * as promptActions from '../actions/promptActions';
import * as statusActions from '../actions/statusActions';
import * as userActions from '../actions/userActions';
import BackButton from '../components/BackButton';
import Box from '../components/Box';
import Button from '../components/Button';
import Container from '../components/Container';
import EmptyMessage from '../components/EmptyMessage';
import Header from '../components/Header';
import HeaderButton from '../components/HeaderButton';
import HeaderTitle from '../components/HeaderTitle';
import Icon from '../components/Icon';
import Page from '../components/Page';
import Status from '../components/Status';
import TextArea from '../components/TextArea';
import Title from '../components/Title';
import { ALL_PROMPTS, JOURNAL, TODAYS_PROMPT } from '../constants';
import analytics from '../functions/analyticsFunctions';
import { formattedToday, isToday, isYesterday } from '../functions/dateFunctions';
import { download } from '../functions/downloadFunctions';
import { capitalizeFirstLetter } from '../functions/stringFunctions';
import Answer from '../objects/Answer';
import ActionSheet from '../modals/ActionSheet';
import ShareModal from '../modals/ShareModal';

class AnswerPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      changed: false,
      showMenu: false,
      showShareModal: false,
    };

    this.checkPromptParam = this.checkPromptParam.bind(this);
    this.checkSave = this.checkSave.bind(this);
    this.didAnswer = this.didAnswer.bind(this);
    this.didChangeAnswer = this.didChangeAnswer.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleChangeAnswer = this.handleChangeAnswer.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
    this.handleNewAnswer = this.handleNewAnswer.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleSettings = this.handleSettings.bind(this);
    this.loadRandom = this.loadRandom.bind(this);
    this.saveAnswer = this.saveAnswer.bind(this);
  }

  componentDidMount() {
    this.checkPromptParam(this.props);

    window.addEventListener('beforeunload', this.checkSave);
  }

  componentWillReceiveProps(nextProps) {
    this.checkPromptParam(nextProps);
  }

  componentWillUnmount() {
    const { clearStatus } = this.props;
    clearStatus();
    this.checkSave();

    window.removeEventListener('beforeunload', this.checkSave);
  }

  checkPromptParam(nextProps) {
    const {
      user,
      prompts,
      match,
      answer,
      createNewAnswer,
      loadCategory,
      category,
      location,
      todayPrompt,
      answers,
      loadAnswer,
    } = nextProps;
    const { params } = match;

    if (params && params.promptId) {
      if (params.promptId === answer.prompt_id) {
        return;
      } else {
        if (prompts.length) {
          const prompt = prompts.find(p => p._id === params.promptId);
          if (prompt) {
            if (
              category !== ALL_PROMPTS &&
              category !== 'Answers' &&
              !prompt.categories.includes(category)
            ) {
              loadCategory(ALL_PROMPTS);
            }

            createNewAnswer(
              new Answer(
                user._id,
                user.name,
                prompt._id,
                prompt.title,
                prompt.type,
                prompt.categories
              )
            );

            return;
          } else {
            this.props.history.push('/');

            return;
          }
        }
      }
    } else if (location.pathname.split('/')[2] === 'today') {
      if (answer.prompt_id === todayPrompt) return;

      if (answers.length) {
        const answerFound = answers.find(a => a.prompt_id === todayPrompt && isToday(a.answered));
        if (answerFound) {
          if (category !== TODAYS_PROMPT) {
            loadCategory(TODAYS_PROMPT);
          }
          loadAnswer(answerFound);
          return;
        }
      }
      if (prompts.length) {
        const prompt = prompts.find(p => p._id === todayPrompt);
        if (prompt) {
          if (category !== TODAYS_PROMPT) {
            loadCategory(TODAYS_PROMPT);
          }

          createNewAnswer(
            new Answer(
              user._id,
              user.name,
              prompt._id,
              prompt.title,
              prompt.type,
              prompt.categories
            )
          );
          return;
        } else {
          this.props.history.push('/');
          return;
        }
      }
    } else if (location.pathname.split('/')[2] === 'journal') {
      if (answer.prompt_title === formattedToday()) {
        return;
      }
      if (params.answerId) {
        return;
      }
      if (answers.length) {
        const answerFound = answers.find(a => a.prompt_title === formattedToday());
        if (answerFound) {
          if (category !== JOURNAL) {
            loadCategory(JOURNAL);
          }
          loadAnswer(answerFound);
          return;
        }
      }
      if (category !== JOURNAL) {
        loadCategory(JOURNAL);
      }

      createNewAnswer(
        new Answer(user._id, user.name, '', formattedToday(), 'paragraph', [JOURNAL])
      );
    } else {
      if (answer.prompt_title && answer.prompt_id) {
        this.props.history.replace(`/answer/${answer.prompt_id}`);
      } else {
        this.loadRandom();
      }
    }
  }

  checkSave() {
    if (this.didChangeAnswer()) {
      this.saveAnswer();
    }
  }

  didAnswer() {
    const { answer } = this.props;
    return answer.text;
  }

  didChangeAnswer() {
    const { changed } = this.state;
    return !!changed;
  }

  loadRandom() {
    let { createNewAnswer, loadRandomPrompt, user } = this.props;

    const prompt = loadRandomPrompt();

    if (prompt) {
      createNewAnswer(
        new Answer(user._id, user.name, prompt._id, prompt.title, prompt.type, prompt.categories)
      );
      this.props.history.replace(`/answer/${prompt._id}`);
    } else {
      this.props.history.push('/');
    }
  }

  saveAnswer() {
    let { answer, answers, resetStreak, saveAnswer, setStatus, updateStreak, user } = this.props;

    if (this.didChangeAnswer()) {
      const newAnswerScore = Math.round(answer.text.split(' ').length / 3);
      let score = 0;

      const oldAnswerIndex = answers.findIndex(a => a._id === answer._id);
      if (oldAnswerIndex === -1) {
        score = newAnswerScore;
      } else {
        score = newAnswerScore - Math.round(answers[oldAnswerIndex].text.split(' ').length / 3);
      }
      answer.score = score;

      saveAnswer(answer);
      setStatus('Answer saved');

      if (!user.last) {
        let newDate = new Date();
        newDate.setDate(newDate.getDate() - 1);
        user.last = newDate;
        user.streak = 0;
      }

      if (isToday(user.last)) {
        return;
      } else if (isYesterday(user.last)) {
        updateStreak(user);
      } else {
        resetStreak(user);
      }
    }
  }

  handleSettings() {
    this.checkSave();
    this.props.history.push('/answerSettings');
  }

  onClick(e) {
    e.stopPropagation ? e.stopPropagation() : (e.cancelBubble = true);
  }

  handleBack() {
    this.props.history.goBack();
  }

  focusAnswer() {
    const elem = document.getElementById('answer');
    elem.focus();
    let tmp = elem.value;
    elem.value = '';
    elem.value = tmp;
  }

  handleChangeAnswer(evt) {
    this.props.changeAnswerText(capitalizeFirstLetter(evt.target.value));
    this.setState({ changed: true });
  }

  handleDownload() {
    const { answer } = this.props;

    analytics.track('download answer', { from: 'answer' });

    download([answer]);
  }

  handleNext() {
    let { category, answer, answers, history, loadAnswer } = this.props;

    if (category === JOURNAL || category === TODAYS_PROMPT) {
      if (this.didAnswer()) {
        this.checkSave();
        history.push('/streak');
      }
      return;
    }

    if (category === 'Answers') {
      this.checkSave();
      let answerIndex = answers.findIndex(a => a._id === answer._id);
      if (answerIndex <= 0) {
        this.handleBack();
      } else {
        const a = answers[answerIndex - 1];
        loadAnswer(a);
        this.props.history.replace(`/answer/${a.prompt_id}`);
      }
      return;
    }

    if (this.didChangeAnswer()) {
      this.checkSave();
      history.push('/streak');
    } else {
      this.loadRandom();
    }
  }

  handleMenu(e) {
    if (e) {
      if (e.stopPropagation) {
        e.stopPropagation();
      } else {
        e.cancelBubble = true;
      }
    }
    this.checkSave();
    this.setState({ showMenu: !this.state.showMenu });
  }

  handleNewAnswer() {
    const { user, answer, createNewAnswer, category, loadCategory } = this.props;

    if (category === 'Answers') {
      loadCategory(answer.categories[0]);
    }

    analytics.track('new answer');

    createNewAnswer(
      new Answer(
        user._id,
        user.name,
        answer.prompt_id,
        answer.prompt_title,
        answer.type,
        answer.categories
      )
    );

    this.props.history.replace(`/answer/${answer.prompt_id}`);
  }

  render() {
    const { answer, answers, prompts, loading, category } = this.props;
    const { showMenu, showShareModal } = this.state;

    const viewingJournal = category === JOURNAL;

    let menus = [];

    answer.text && menus.push({ content: 'New answer', onClick: this.handleNewAnswer });

    const filteredAnswers = answers.filter(a => a.prompt_id === answer.prompt_id);
    filteredAnswers.length > 0 &&
      menus.push({ content: 'Show prompt answers', onClick: this.handleSettings });

    menus.push({
      content: 'Share',
      onClick: () => {
        this.checkSave();
        this.setState({ showShareModal: true });
        analytics.track('share');
      },
    });

    answer.text && menus.push({ content: 'Download answer', onClick: this.handleDownload });

    const title = category ? category : ALL_PROMPTS;

    return (
      <Page>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Status />
        <Header>
          <BackButton />
          <HeaderTitle>{title}</HeaderTitle>
          <HeaderButton onClick={this.handleNext}>
            {category === JOURNAL || category === TODAYS_PROMPT ? 'Done' : 'Next'}
          </HeaderButton>
        </Header>
        <Container>
          {loading && prompts.length === 0 ? (
            <EmptyMessage>Loading...</EmptyMessage>
          ) : (
            <Box
              flexDirection="column"
              flexGrow={1}
              flexShrink={1}
              key={answer._id}
              noOverflow
              onClick={this.focusAnswer}
              padding="none none medium"
            >
              <Box noOverflow width="full">
                <Button
                  alignItems="center"
                  id="more"
                  flexGrow={1}
                  flexShrink={1}
                  justifyContent="spaceBetween"
                  noShadow
                  onClick={e => {
                    this.checkSave();
                    !viewingJournal && !!menus.length && this.handleMenu(e);
                    analytics.track('prompt menu');
                  }}
                  padding="medium medium small"
                  paddingRight="none"
                >
                  <Title flexShrink={1} fontSize="xLarge" width="full">
                    {answer.prompt_title}
                  </Title>
                  <Box paddingX="large">
                    <Icon type="more" />
                  </Box>
                </Button>
              </Box>
              <TextArea
                autoFocus
                id="answer"
                noBackground
                value={answer.text}
                type="text"
                onChange={this.handleChangeAnswer}
                onClick={this.onClick}
                placeholder="Müse here..."
              />
            </Box>
          )}
        </Container>
        {showMenu && <ActionSheet handleClose={this.handleMenu} menus={menus} />}
        {showShareModal && (
          <ShareModal
            answer={answer}
            onClose={() => this.setState({ showShareModal: false })}
          />
        )}
      </Page>
    );
  }
}

AnswerPage.propTypes = {
  answer: PropTypes.object.isRequired,
  answers: PropTypes.array.isRequired,
  category: PropTypes.string,
  changeAnswerText: PropTypes.func.isRequired,
  clearStatus: PropTypes.func.isRequired,
  createNewAnswer: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  loadAnswer: PropTypes.func.isRequired,
  loadCategory: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loadRandomPrompt: PropTypes.func.isRequired,
  prompts: PropTypes.array.isRequired,
  resetStreak: PropTypes.func.isRequired,
  saveAnswer: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  updateStreak: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = ({
  user,
  answer,
  answers,
  prompts,
  myPrompts,
  loading,
  category,
  todayPrompt,
}) => {

  return { user, answer, answers, prompts, loading, category, todayPrompt };
};

AnswerPage = connect(mapStateToProps, {
  ...answerActions,
  ...loginActions,
  ...promptActions,
  ...statusActions,
  ...userActions
})(AnswerPage);

export default AnswerPage;
