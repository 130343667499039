import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const reportedAnswersReducer = (state = initialState.reportedAnswers, action) => {
  switch (action.type) {
    case ActionTypes.UNREPORT_ANSWER:
      let reportedAnswerIndex = state.findIndex(a => a._id === action.answer_id);
      return [...state.slice(0, reportedAnswerIndex), ...state.slice(reportedAnswerIndex + 1)];

    case ActionTypes.LOAD_REPORTED_ANSWERS_SUCCESS:
      return action.reportedAnswers;

    default:
      return state;
  }
};

export default reportedAnswersReducer;
