import initialState from './initialState'
import ActionTypes from '../actions/actionTypes'

const newAdminReducer = (state = initialState.newAdmin, action) => {
  switch(action.type) {

    case ActionTypes.CHANGE_ADMIN_NAME:
      return action.name

    case ActionTypes.ADD_ADMIN:
      return ''

    default:
      return state
  }
}

export default newAdminReducer
