import PropTypes from 'prop-types';
import React from 'react';

import { colors, fontSizes, widths } from '../constants';
import { margin, padding } from '../functions/styleFunctions';

const styles = ({
  backgroundColor,
  bottom,
  color,
  flexBasis,
  flexGrow,
  flexShrink,
  fontSize,
  flexWrap,
  left,
  lineHeight,
  maxWidth,
  noBreak,
  position,
  right,
  textAlign,
  top,
  truncate,
  width,
  ...props
}) => ({
  backgroundColor: colors[backgroundColor],
  bottom,
  color: colors[color],
  flexBasis,
  flexGrow,
  flexShrink,
  flexWrap,
  fontSize: fontSizes[fontSize] || fontSize,
  hyphens: 'auto',
  left,
  lineHeight,
  ...margin(props),
  maxWidth,
  overflow: truncate ? 'hidden' : 'visible',
  ...padding(props),
  position,
  right,
  textAlign,
  textOverflow: truncate ? 'ellipsis' : 'initial',
  top,
  whiteSpace: truncate ? 'nowrap' : 'pre-line',
  width: widths[truncate ? 'full' : width] || width || widths.auto,
  wordBreak: noBreak ? 'normal' : 'break-word'
});

const Text = ({
  backgroundColor,
  bottom,
  children,
  color,
  component,
  dispatch,
  flexBasis,
  flexGrow,
  flexShrink,
  fontSize,
  left,
  lineHeight,
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  marginX,
  marginY,
  maxWidth,
  noBreak,
  padding,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingX,
  paddingY,
  position,
  right,
  textAlign,
  top,
  truncate,
  width,
  ...props
}) => {
  const Component = component;

  return (
    <Component
      className="text"
      style={styles({
        backgroundColor,
        bottom,
        color,
        dispatch,
        flexBasis,
        flexGrow,
        flexShrink,
        fontSize,
        left,
        lineHeight,
        margin,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
        marginX,
        marginY,
        maxWidth,
        noBreak,
        padding,
        paddingBottom,
        paddingLeft,
        paddingRight,
        paddingTop,
        paddingX,
        paddingY,
        position,
        right,
        textAlign,
        top,
        truncate,
        width
      })}
      {...props}
    >
      {children}
    </Component>
  );
};

Text.propTypes = {
  backgroundColor: PropTypes.string,
  bottom: PropTypes.string,
  children: PropTypes.any,
  color: PropTypes.string,
  component: PropTypes.string.isRequired,
  flexBasis: PropTypes.string,
  flexGrow: PropTypes.any,
  flexShrink: PropTypes.any,
  fontSize: PropTypes.string,
  left: PropTypes.string,
  lineHeight: PropTypes.any,
  margin: PropTypes.string,
  marginBottom: PropTypes.string,
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string,
  marginTop: PropTypes.string,
  marginX: PropTypes.any,
  marginY: PropTypes.any,
  maxWidth: PropTypes.string,
  noBreak: PropTypes.bool,
  padding: PropTypes.any,
  paddingBottom: PropTypes.any,
  paddingLeft: PropTypes.any,
  paddingRight: PropTypes.any,
  paddingTop: PropTypes.any,
  paddingX: PropTypes.any,
  paddingY: PropTypes.any,
  position: PropTypes.string,
  right: PropTypes.string,
  textAlign: PropTypes.string,
  top: PropTypes.string,
  truncate: PropTypes.bool,
  width: PropTypes.string
};

Text.defaultProps = {
  backgroundColor: 'transparent',
  bottom: 'auto',
  color: 'black',
  component: 'p',
  flexBasis: 'auto',
  flexGrow: 0,
  flexShrink: 0,
  fontSize: 'medium',
  left: 'auto',
  lineHeight: 1.5,
  margin: null,
  maxWidth: 'auto',
  noBreak: false,
  padding: null,
  position: 'relative',
  right: 'auto',
  textAlign: 'left',
  top: 'auto',
  width: 'auto'
};

export default Text;
