// src/localStorage.js

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('reflectState');
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const newState = { ...state, data: { users: [], answers: [], prompts: [] } };
    const serializedState = JSON.stringify(newState);
    localStorage.setItem('reflectState', serializedState);
  } catch (err) {
    // ignore write errors
    console.log('Failed to save sate', err);
  }
};
