const capitalizeFirstLetter = str => {
  return str.charAt(0).toUpperCase() + str.substr(1);
};

const checkLettersAndNumbers = str => {
  return /^\w+$/.test(str);
};

/* eslint-disable */
const checkEmail = str => {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    str
  );
};
/* eslint-enable */

export { capitalizeFirstLetter, checkEmail, checkLettersAndNumbers };
