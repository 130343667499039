import { combineReducers } from 'redux';
import admin from './adminReducer';
import answer from './answerReducer';
import answers from './answersReducer';
import categories from './categoriesReducer';
import category from './categoryReducer';
import data from './dataReducer';
import editPrompt from './editPromptReducer';
import editPrompts from './editPromptsReducer';
import editUser from './editUserReducer';
import error from './errorReducer';
import ids from './idsReducer';
import loading from './loadingReducer';
import login from './loginReducer';
import myPrompts from './myPromptsReducer';
import newAdmin from './newAdminReducer';
import newAdminPrompt from './newAdminPromptReducer';
import newPrompt from './newPromptReducer';
import offline from './offlineReducer';
import otherUser from './otherUserReducer';
import prompts from './promptsReducer';
import reportedAnswers from './reportedAnswersReducer';
import search from './searchReducer';
import seen from './seenReducer';
import sideMenu from './sideMenuReducer';
import status from './statusReducer';
import submittedPrompts from './submittedPromptsReducer';
import todayPrompt from './todayPromptReducer';
import todayPromptDate from './todayPromptDateReducer';
import user from './userReducer';
import world from './worldReducer';

const rootReducer = combineReducers({
  admin,
  answer,
  answers,
  categories,
  category,
  data,
  editPrompt,
  editPrompts,
  editUser,
  error,
  ids,
  loading,
  login,
  myPrompts,
  newAdmin,
  newAdminPrompt,
  newPrompt,
  offline,
  otherUser,
  prompts,
  reportedAnswers,
  search,
  seen,
  world,
  sideMenu,
  status,
  submittedPrompts,
  todayPrompt,
  todayPromptDate,
  user
});

export default rootReducer;
