import axios from 'axios';
import ActionTypes from './actionTypes';
import { loadUserAnswers } from './userActions';
import { loadUserPrompts } from './promptActions';
import analytics from '../functions/analyticsFunctions';

// change login name
export const changeLoginName = name => ({ type: ActionTypes.CHANGE_LOGIN_NAME, name });

// change login email
export const changeLoginEmail = email => ({ type: ActionTypes.CHANGE_LOGIN_EMAIL, email });

// change login password
export const changeLoginPassword = password => ({
  type: ActionTypes.CHANGE_LOGIN_PASSWORD,
  password
});

// change state of login
export const changeLoginState = login => ({ type: ActionTypes.CHANGE_LOGIN_STATE, login });

// sign up user
export const signupUser = user => dispatch => {
  dispatch({ type: ActionTypes.SIGNUP_USER_REQUEST }); // send sign up user request

  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const day = now.getDate();

  analytics.track('signup user');

  return axios.post('/api/signup', { user, year, month, day }).then(
    () => dispatch({ type: ActionTypes.SIGNUP_USER_SUCCESS, user }), // sign up successful
    () => dispatch({ type: ActionTypes.SIGNUP_USER_FAIL, error: 'Sorry, name or email is taken' }) // sign up failed
  );
};

// login user
export const loginUser = user => dispatch => {
  dispatch({ type: ActionTypes.LOGIN_USER_REQUEST });

  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const day = now.getDate();

  analytics.track('login user');

  return axios.post('/api/login', { user, year, month, day }).then(
    response => {
      dispatch({ type: ActionTypes.LOGIN_USER_SUCCESS, user: response.data });
      dispatch(loadUserPrompts(response.data));
      dispatch(loadUserAnswers(response.data));
    },
    () => {
      dispatch({ type: ActionTypes.LOGIN_USER_FAIL, error: 'Sorry, password does not match' });
    }
  );
};

// clear login
export const clearLogin = () => ({ type: ActionTypes.CLEAR_LOGIN });

// pick login
export const pickLogin = () => ({ type: ActionTypes.PICK_LOGIN });

// pick signup
export const pickSignup = () => ({ type: ActionTypes.PICK_SIGNUP });
