import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  alignments,
  borderRadii,
  // boxShadow,
  colors,
  fontSizes,
  widths
} from '../constants';
import { margin, padding } from '../functions/styleFunctions';

const styles = ({
  alignItems,
  backgroundColor,
  borderRadius,
  color,
  disabled,
  flexBasis,
  flexDirection,
  flexGrow,
  flexShrink,
  fontSize,
  height,
  hide,
  justifyContent,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  noShadow,
  position,
  style,
  textAlign,
  width,
  ...props
}) => ({
  alignItems: alignments[alignItems],
  backgroundColor: colors[hide ? 'white' : disabled ? 'white' : backgroundColor],
  borderRadius: borderRadii[borderRadius],
  boxShadow: hide || noShadow ? null : '0px 0px 8px 1px rgba(0, 0, 0, 0.2)',
  color: colors[color],
  cursor: disabled ? 'not-allowed' : hide ? 'default' : 'pointer',
  display: 'flex',
  flexBasis,
  flexDirection,
  flexGrow,
  flexShrink,
  fontSize: fontSizes[fontSize],
  height,
  justifyContent: alignments[justifyContent],
  ...margin(props),
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  ...padding(props),
  position,
  textAlign,
  width: widths[width] || width,
  ...style
});

const Button = ({
  alignItems,
  backgroundColor,
  borderRadius,
  children,
  color,
  disabled,
  flexBasis,
  flexDirection,
  flexGrow,
  flexShrink,
  fontSize,
  height,
  hide,
  href,
  justifyContent,
  left,
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  marginX,
  marginY,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  noShadow,
  padding,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingX,
  paddingY,
  position,
  right,
  staticContext,
  style,
  to,
  textAlign,
  width,
  ...props
}) => {
  const Component = href ? 'a' : to ? Link : 'button';
  return (
    <Component
      className="button"
      style={styles({
        alignItems,
        backgroundColor,
        borderRadius,
        color,
        disabled,
        flexBasis,
        flexDirection,
        flexGrow,
        flexShrink,
        fontSize,
        height,
        hide,
        justifyContent,
        left,
        margin,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
        marginX,
        marginY,
        maxHeight,
        maxWidth,
        minHeight,
        minWidth,
        noShadow,
        padding,
        paddingBottom,
        paddingLeft,
        paddingRight,
        paddingTop,
        paddingX,
        paddingY,
        position,
        right,
        style,
        textAlign,
        width
      })}
      href={href}
      to={to}
      {...props}
    >
      {children}
    </Component>
  );
};

Button.propTypes = {
  alignItems: PropTypes.string,
  backgroundColor: PropTypes.any,
  borderRadius: PropTypes.string,
  children: PropTypes.any,
  color: PropTypes.any,
  disabled: PropTypes.bool,
  flexBasis: PropTypes.string,
  flexDirection: PropTypes.string,
  flexGrow: PropTypes.number,
  flexShrink: PropTypes.number,
  fontSize: PropTypes.string,
  hide: PropTypes.bool,
  href: PropTypes.string,
  justifyContent: PropTypes.string,
  left: PropTypes.any,
  margin: PropTypes.string,
  marginBottom: PropTypes.string,
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string,
  marginTop: PropTypes.string,
  marginX: PropTypes.any,
  marginY: PropTypes.any,
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  minHeight: PropTypes.string,
  minWidth: PropTypes.string,
  noShadow: PropTypes.bool,
  padding: PropTypes.string,
  paddingBottom: PropTypes.string,
  paddingLeft: PropTypes.string,
  paddingRight: PropTypes.string,
  paddingTop: PropTypes.string,
  paddingX: PropTypes.any,
  paddingY: PropTypes.any,
  position: PropTypes.string,
  right: PropTypes.any,
  style: PropTypes.string,
  textAlign: PropTypes.string,
  to: PropTypes.string,
  width: PropTypes.string
};

Button.defaultProps = {
  alignItems: 'center',
  backgroundColor: 'white',
  borderRadius: 'circle',
  color: 'black',
  flexBasis: 'auto',
  flexDirection: 'row',
  flexGrow: 0,
  flexShrink: 0,
  fontSize: 'medium',
  hide: false,
  justifyContent: 'center',
  maxHeight: 'auto',
  maxWidth: 'auto',
  minHeight: 'auto',
  minWidth: 'auto',
  noShadow: false,
  padding: 'medium large',
  position: 'relative',
  textAlign: 'center',
  to: null,
  width: 'auto'
};

export default Button;
