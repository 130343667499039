import PropTypes from 'prop-types';
import React from 'react';

import { boxShadow, spaces, widths } from '../constants';

const styles = ({ flexDirection, flexShrink, noBorder }) => ({
  footer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    flexShrink,
    overflowY: 'scroll',
    boxShadow: noBorder ? 'none' : boxShadow
  },
  content: {
    display: 'flex',
    flexDirection,
    height: '100%',
    padding: spaces.medium,
    maxWidth: widths.contain,
    margin: '0 auto',
    width: '100%'
  }
});

const Footer = ({ children, ...props }) => {
  const style = styles(props);
  return (
    <div className="footer" style={style.footer}>
      <div style={style.content}>{children}</div>
    </div>
  );
};

Footer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  noBorder: PropTypes.any
};

Footer.defaultProps = {
  flexDirection: 'row',
  flexShrink: 0
};

export default Footer;
