export default {
  PICK_LOGIN: 'PICK_LOGIN',
  PICK_SIGNUP: 'PICK_SIGNUP',

  CHANGE_EDIT_USER_NAME: 'CHANGE_EDIT_USER_NAME',
  CHANGE_EDIT_USER_EMAIL: 'CHANGE_EDIT_USER_EMAIL',
  EDIT_USER: 'EDIT_USER',
  SAVE_EDIT_USER_REQUEST: 'SAVE_EDIT_USER_REQUEST',
  SAVE_EDIT_USER_SUCCESS: 'SAVE_EDIT_USER_SUCCESS',
  SAVE_EDIT_USER_FAIL: 'SAVE_EDIT_USER_FAIL',

  UPDATE_PROFILE_PICTURE_SUCCESS: 'UPDATE_PROFILE_PICTURE_SUCCESS',
  UPDATE_PROFILE_PICTURE_OFFLINE: 'UPDATE_PROFILE_PICTURE_OFFLINE',

  SET_LOADING: 'SET_LOADING',

  CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
  CREATE_USER_FAIL: 'CREATE_USER_FAIL',

  CREATE_NEW_ANSWER: 'CREATE_NEW_ANSWER',

  LOAD_ANSWER: 'LOAD_ANSWER',

  SAVE_ANSWER: 'SAVE_ANSWER',
  SAVE_ANSWER_OFFLINE: 'SAVE_ANSWER_OFFLINE',

  CHANGE_ANSWER_TEXT: 'CHANGE_ANSWER_TEXT',

  CHANGE_SEARCH: 'CHANGE_SEARCH',

  LOAD_CATEGORY: 'LOAD_CATEGORY',

  PICK_USER_CATEGORY: 'PICK_USER_CATEGORY',
  SAVE_USER_CATEGORIES: 'SAVE_USER_CATEGORIES',
  LOAD_PICKED_CATEGORIES: 'LOAD_PICKED_CATEGORIES',

  DELETE_ANSWER: 'DELETE_ANSWER',
  DELETE_ANSWER_OFFLINE: 'DELETE_ANSWER_OFFLINE',

  LOAD_SHARED_ANSWERS_REQUEST: 'LOAD_SHARED_ANSWERS_REQUEST',
  LOAD_SHARED_ANSWERS_SUCCESS: 'LOAD_SHARED_ANSWERS_SUCCESS',

  SHARE_ANSWER: 'SHARE_ANSWER',
  UNSHARE_ANSWER: 'UNSHARE_ANSWER',

  LIKE_ANSWER: 'LIKE_ANSWER',
  UNLIKE_ANSWER: 'UNLIKE_ANSWER',

  CHANGE_NEW_PROMPT_TITLE: 'CHANGE_NEW_PROMPT_TITLE',
  PICK_NEW_PROMPT_CATEGORY: 'PICK_NEW_PROMPT_CATEGORY',
  CREATE_NEW_PROMPT: 'CREATE_NEW_PROMPT',
  CREATE_NEW_PROMPT_OFFLINE: 'CREATE_NEW_PROMPT_OFFLINE',
  CLEAR_NEW_PROMPT: 'CLEAR_NEW_PROMPT',

  CHANGE_LOGIN_NAME: 'CHANGE_LOGIN_NAME',
  CHANGE_LOGIN_EMAIL: 'CHANGE_LOGIN_EMAIL',
  CHANGE_LOGIN_PASSWORD: 'CHANGE_LOGIN_PASSWORD',
  CHANGE_LOGIN_STATE: 'CHANGE_LOGIN_STATE',
  SIGNUP_USER_REQUEST: 'SIGNUP_USER_REQUEST',
  SIGNUP_USER_SUCCESS: 'SIGNUP_USER_SUCCESS',
  SIGNUP_USER_FAIL: 'SIGNUP_USER_FAIL',
  LOGIN_USER_REQUEST: 'LOGIN_USER_REQUEST',
  LOGIN_USER_SUCCESS: 'LOGIN_USER_SUCCESS',
  LOGIN_USER_FAIL: 'LOGIN_USER_FAIL',
  CLEAR_LOGIN: 'CLEAR_LOGIN',

  LOGOUT: 'LOGOUT',

  EDIT_PROMPT: 'EDIT_PROMPT',
  CLEAR_EDIT_PROMPT: 'CLEAR_EDIT_PROMPT',
  CHANGE_EDIT_PROMPT_TITLE: 'CHANGE_EDIT_PROMPT_TITLE',
  PICK_EDIT_PROMPT_CATEGORY: 'PICK_EDIT_PROMPT_CATEGORY',
  DELETE_PROMPT: 'DELETE_PROMPT',
  SAVE_EDIT_PROMPT: 'SAVE_EDIT_PROMPT',

  CLEAR_NEW_ADMIN_PROMPT: 'CLEAR_NEW_ADMIN_PROMPT',
  CHANGE_NEW_ADMIN_PROMPT_TITLE: 'CHANGE_NEW_ADMIN_PROMPT_TITLE',
  PICK_NEW_ADMIN_PROMPT_CATEGORY: 'PICK_NEW_ADMIN_PROMPT_CATEGORY',
  CREATE_NEW_ADMIN_PROMPT: 'CREATE_NEW_ADMIN_PROMPT',

  APPROVE_NEW_PROMPT: 'APPROVE_NEW_PROMPT',
  REJECT_NEW_PROMPT: 'REJECT_NEW_PROMPT',

  LOAD_ADMIN_SUCCESS: 'LOAD_ADMIN_SUCCESS',
  CHANGE_ADMIN_NAME: 'CHANGE_ADMIN_NAME',
  ADD_ADMIN: 'ADD_ADMIN',
  REMOVE_ADMIN: 'REMOVE_ADMIN',
  LOAD_ADMIN_ANSWERS_SUCCESS: 'LOAD_ADMIN_ANSWERS_SUCCESS',
  LOAD_ADMIN_USERS_SUCCESS: 'LOAD_ADMIN_USERS_SUCCESS',
  LOAD_ADMIN_PROMPTS_SUCCESS: 'LOAD_ADMIN_PROMPTS_SUCCESS',
  CLEAR_DATA: 'CLEAR_DATA',

  SET_ERROR: 'SET_ERROR',
  CLEAR_ERROR: 'CLEAR_ERROR',

  SET_STATUS: 'SET_STATUS',
  CLEAR_STATUS: 'CLEAR_STATUS',

  LOAD_APPROVED_PROMPTS_SUCCESS: 'LOAD_APPROVED_PROMPTS_SUCCESS',
  LOAD_SUBMITTED_PROMPTS_SUCCESS: 'LOAD_SUBMITTED_PROMPTS_SUCCESS',

  LOAD_USER_PROMPTS_SUCCESS: 'LOAD_USER_PROMPTS_SUCCESS',
  LOAD_USER_ANSWERS_SUCCESS: 'LOAD_USER_ANSWERS_SUCCESS',
  LOAD_USER_SUCCESS: 'LOAD_USER_SUCCESS',

  LOAD_OTHER_USER_REQUEST: 'LOAD_OTHER_USER_REQUEST',
  LOAD_OTHER_USER_SUCCESS: 'LOAD_OTHER_USER_SUCCESS',

  LOAD_CATEGORIES_SUCCESS: 'LOAD_CATEGORIES_SUCCESS',

  UPDATE_STREAK: 'UPDATE_STREAK',
  UPDATE_STREAK_SUCCESS: 'UPDATE_STREAK_SUCCESS',
  UPDATE_STREAK_OFFLINE: 'UPDATE_STREAK_OFFLINE',
  RESET_STREAK: 'RESET_STREAK',
  RESET_STREAK_SUCCESS: 'RESET_STREAK_SUCCESS',
  RESET_STREAK_OFFLINE: 'RESET_STREAK_OFFLINE',

  LOAD_EDIT_PROMPTS: 'LOAD_EDIT_PROMPTS',
  CHANGE_EDIT_PROMPTS: 'CHANGE_EDIT_PROMPTS',
  SAVE_EDIT_PROMPTS: 'SAVE_EDIT_PROMPTS',

  UPDATE_TODAY_PROMPT_SUCCESS: 'UPDATE_TODAY_PROMPT_SUCCESS',
  LOAD_TODAY_PROMPT_SUCCESS: 'LOAD_TODAY_PROMPT_SUCCESS',

  RESET_SEEN: 'RESET_SEEN',

  OFFLINE_SAVE: 'OFFLINE_SAVE',
  OFFLINE_SAVE_SUCCESS: 'OFFLINE_SAVE_SUCCESS',
  OFFLINE_SAVE_FAIL: 'OFFLINE_SAVE_FAIL',

  SET_EMAIL: 'SET_EMAIL',
  SEND_EMAIL: 'SEND_EMAIL',
  CLOSE_EMAIL: 'CLOSE_EMAIL',

  SET_CODE: 'SET_CODE',

  CLOSE_SIDE_MENU: 'CLOSE_SIDE_MENU',
  TOGGLE_SIDE_MENU: 'TOGGLE_SIDE_MENU',

  SET_ANSWER_ID: 'SET_ANSWER_ID',
  SET_USER_ANSWER_ID: 'SET_USER_ANSWER_ID',
  SET_WORLD_ANSWER_ID: 'SET_WORLD_ANSWER_ID',

  REPORT_ANSWER: 'REPORT_ANSWER',
  UNREPORT_ANSWER: 'UNREPORT_ANSWER',
  LOAD_REPORTED_ANSWERS_SUCCESS: 'LOAD_REPORTED_ANSWERS_SUCCESS',
};
