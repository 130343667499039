import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const userReducer = (state = initialState.user, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_USER_REQUEST:
      return { ...state, _id: action.user._id, categories: action.user.categories };

    case ActionTypes.SAVE_ANSWER:
      let newSaveAnswerState = { ...state };
      newSaveAnswerState.score += action.answer.score;
      return newSaveAnswerState;

    case ActionTypes.SAVE_USER_CATEGORIES:
      return { ...state, categories: action.categories };

    case ActionTypes.DELETE_ANSWER:
      let deleteAnswerIndex = state.answered.indexOf(action.answer_id);
      return {
        ...state,
        answered: [
          ...state.answered.slice(0, deleteAnswerIndex),
          ...state.answered.slice(deleteAnswerIndex + 1)
        ]
      };

    case ActionTypes.CREATE_NEW_PROMPT:
      return { ...state, created: [...state.created, action.prompt._id] };

    case ActionTypes.LOGOUT:
      let newDate = new Date();
      newDate.setDate(newDate.getDate() - 1);
      return { ...initialState.user, last: newDate };

    case ActionTypes.SIGNUP_USER_SUCCESS:
      return { ...state, name: action.user.name, email: action.user.email };

    case ActionTypes.UPDATE_STREAK:
      let newState = { ...state };
      const streak = newState.streak + 1;
      const longest = newState.longestStreak > streak ? newState.longestStreak : streak;
      return { ...state, streak: streak, last: new Date(), longestStreak: longest };

    case ActionTypes.UPDATE_STREAK_SUCCESS:
    case ActionTypes.UPDATE_PROFILE_PICTURE_SUCCESS:
    case ActionTypes.RESET_STREAK_SUCCESS:
    case ActionTypes.LOGIN_USER_SUCCESS:
    case ActionTypes.LOAD_USER_SUCCESS:
    case ActionTypes.OFFLINE_SAVE_SUCCESS:
      return action.user || state;

    case ActionTypes.RESET_STREAK:
      return { ...state, streak: 1, last: new Date() };

    case ActionTypes.SAVE_EDIT_USER_SUCCESS:
      return { ...state, name: action.user.name, email: action.user.email };

    case ActionTypes.SET_CODE:
      return { ...state, code: action.code };

    default:
      return state;
  }
};

export default userReducer;
