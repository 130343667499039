import React from 'react';

import Box from './Box';

const Card = props => <Box className="card" {...props} />;

Card.defaultProps = {
  alignItems: 'stretch',
  backgroundColor: 'white',
  borderRadius: 'round',
  boxShadow: true,
  flexDirection: 'column',
  flexShrink: '0',
  marginBottom: 'large',
  padding: 'medium',
  width: 'full',
};

export default Card;
