// src/components/Toggle.js

import PropTypes from 'prop-types';
import React from 'react';

import Button from './Button';
import Icon from './Icon';
import Text from './Text';

const Toggle = ({ label, value, ...props }) => (
  <Button noShadow {...props}>
    <Text flexGrow="1">{label}</Text>
    <Icon color={value ? 'green' : 'darkGrey'} size="large" type={value ? 'toggled' : 'toggle'} />
  </Button>
);

Toggle.defaultProps = {
  width: 'full',
}

Toggle.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired
};

export default Toggle;
