import React from 'react';

import Title from './Title';

const HeaderTitle = props => <Title className="header-title" {...props} />;

HeaderTitle.defaultProps = {
  component: 'h1',
  fontSize: 'large',
  flexBasis: '100%',
  flexGrow: 1,
  flexShrink: 1,
  textAlign: 'center'
};

export default HeaderTitle;
