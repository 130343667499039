import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const otherUserReducer = (state = initialState.otherUser, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_OTHER_USER_REQUEST:
      return state._id === action.id ? state : { _id: action.id };

    case ActionTypes.LOAD_OTHER_USER_SUCCESS:
      return action.user;

    default:
      return state;
  }
};

export default otherUserReducer;
