import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const adminReducer = (state = initialState.admin, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_ADMIN_SUCCESS:
      return action.admin;

    case ActionTypes.ADD_ADMIN:
      return [...state, { name: action.admin_name }];

    case ActionTypes.REMOVE_ADMIN:
      let removeAdminIndex = state.findIndex(admin => admin.name === action.admin_name);
      return [...state.slice(0, removeAdminIndex), ...state.slice(removeAdminIndex + 1)];

    default:
      return state;
  }
};

export default adminReducer;
