// src/pages/UserProfilePage

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as loginActions from '../actions/loginActions';
import * as promptActions from '../actions/promptActions';
import * as userActions from '../actions/userActions';
import BackButton from '../components/BackButton';
import Box from '../components/Box';
import Button from '../components/Button';
import Container from '../components/Container';
import Header from '../components/Header';
import HeaderButton from '../components/HeaderButton';
import HeaderTitle from '../components/HeaderTitle';
import Icon from '../components/Icon';
import Img from '../components/Img';
import Page from '../components/Page';
import Status from '../components/Status';
import Text from '../components/Text';
import Title from '../components/Title';
import analytics from '../functions/analyticsFunctions';
import { formattedDate } from '../functions/dateFunctions';
import { download } from '../functions/downloadFunctions';

class UserProfilePage extends Component {
  constructor(props) {
    super(props);

    this.handleSignup = this.handleSignup.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleSettings = this.handleSettings.bind(this);
    this.handlePhoto = this.handlePhoto.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }

  handleSignup() {
    const { history, pickSignup } = this.props;
    analytics.track('signup', { from: 'profile page' });
    pickSignup();
    history.push('/login');
  }

  handleLogin() {
    const { history, pickLogin } = this.props;
    analytics.track('login', { from: 'profile page' });
    pickLogin();
    history.push('/login');
  }

  handleSettings() {
    const { editUser, history, user } = this.props;
    analytics.track('settings', { from: 'profile page' });
    editUser(user);
    history.push('/userSettings');
  }

  handlePhoto(photo) {
    const { addProfilePicture, user } = this.props;

    // new photo url
    const url = photo.fileUrl;

    // update new post photos
    addProfilePicture(user._id, url);
  }

  handleDownload() {
    const { answers } = this.props;

    analytics.track('download', { from: 'profile page' });

    download(answers);
  }

  render() {
    const { answers, user } = this.props;
    const { longestStreak, name, profilePicture, score, streak } = user;
    const shared = answers.filter(({ shared }) => !!shared).length;

    return (
      <Page itemScope itemType="http://schema.org/Person">
        <Header containerId="profileContainer">
          <BackButton />
          {name ? <HeaderTitle itemProp="name">{name}</HeaderTitle> : <HeaderTitle />}
          {name ? (
            <HeaderButton onClick={this.handleSettings} id="settings">
              <Icon type="settings" />
            </HeaderButton>
          ) : (
            <Box>
              <Button id="loginHeader" onClick={this.handleLogin} marginRight="medium">
                Sign in
              </Button>
              <Button
                backgroundColor="purple"
                color="white"
                id="signupHeader"
                onClick={this.handleSignup}
              >
                Sign up
              </Button>
            </Box>
          )}
        </Header>
        <Status />
        <Container id="profileContainer" paddingX="medium">
          <Box flexShrink="0" justifyContent="center" padding="medium">
            {name && (
              <DropzoneS3Uploader
                className="dropzone"
                maxSize={1024 * 1024 * 100}
                onFinish={this.handlePhoto}
                // onError={decreaseNewPostLoadingPhotos}
                // onProgress={this.handleLoadPhoto}
                s3Url="https://twos.s3.amazonaws.com"
              >
                {profilePicture ? (
                  <Img alt={name} itemProp="image" size="large" src={profilePicture} />
                ) : (
                  <div style={styles.blankPicture}>
                    <Icon type="camera" />
                  </div>
                )}
              </DropzoneS3Uploader>
            )}
          </Box>
          {/* <Label>Stats</Label> */}
          <Box className="mobile-column" justifyContent="spaceAround" noOverflow padding="medium">
            <Box alignItems="center" flexDirection="column" justifyContent="center">
              <Title color="darkGrey">Shared</Title>
              <Title fontSize="xLarge">{shared}</Title>
            </Box>
            <Box alignItems="center" flexDirection="column" justifyContent="center">
              <Title color="darkGrey">Streak</Title>
              <Title fontSize="xLarge">{streak}</Title>
            </Box>
            <Box alignItems="center" flexDirection="column" justifyContent="center">
              <Title color="darkGrey">Long</Title>
              <Title fontSize="xLarge">{longestStreak}</Title>
            </Box>
            <Box alignItems="center" flexDirection="column" justifyContent="center">
              <Title color="darkGrey">Score</Title>
              <Title fontSize="xLarge">{score}</Title>
            </Box>
          </Box>
          <Button
            backgroundColor="purple"
            color="white"
            marginBottom="medium"
            to="/newPrompt"
            id="createPrompt"
            onClick={() => analytics.track('add', { from: 'profile page' })}
          >
            Add a prompt
          </Button>
          <Button
            marginBottom="medium"
            to="/past"
            id="answers"
            onClick={() => analytics.track('answers', { from: 'profile page' })}
          >
            My answers
          </Button>
          <Button
            id="sharedAnswers"
            marginBottom="medium"
            onClick={() => analytics.track('shared', { from: 'profile page' })}
            to="/shared"
          >
            Shared answers
          </Button>
          <Button id="downloadMyAnswers" marginBottom="medium" onClick={this.handleDownload}>
            Download my answers
          </Button>
          <Button
            id="contactUs"
            marginBottom="medium"
            href="mailto:hi@reflectwithmuse.com?subject=Hi Müse"
            itemProp="url"
            target="_blank"
            onClick={() => analytics.track('contact us', { from: 'profile page' })}
          >
            Contact us
          </Button>
          <Text padding="medium" marginBottom="medium" textAlign="center">
            Müsing since {formattedDate(user.started)}
          </Text>
        </Container>
      </Page>
    );
  }
}

const styles = {
  blankPicture: {
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: 32,
    display: 'flex',
    flexShrink: 0,
    height: 64,
    justifyContent: 'center',
    margin: '0 auto 16px',
    width: 64
  }
};

UserProfilePage.propTypes = {
  addProfilePicture: PropTypes.func.isRequired,
  admin: PropTypes.array.isRequired,
  answers: PropTypes.array.isRequired,
  category: PropTypes.string.isRequired,
  editUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  loadCategory: PropTypes.func.isRequired,
  pickLogin: PropTypes.func.isRequired,
  pickSignup: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = ({ answers, user, admin, category }) => ({
  answers,
  user,
  admin,
  category
});

UserProfilePage = connect(mapStateToProps, {
  ...loginActions,
  ...promptActions,
  ...userActions
})(UserProfilePage);

export default withRouter(UserProfilePage);
