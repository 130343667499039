import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as errorActions from '../actions/errorActions';
import {
  changeLoginName,
  changeLoginEmail,
  changeLoginPassword,
  changeLoginState,
  signupUser,
  loginUser,
  clearLogin
} from '../actions/loginActions';
import { setStatus } from '../actions/statusActions';
import { capitalizeFirstLetter, checkEmail } from '../functions/stringFunctions';
import BackButton from '../components/BackButton';
import Box from '../components/Box';
import Button from '../components/Button';
import Container from '../components/Container';
import Error from '../components/Error';
import Form from '../components/Form';
import Header from '../components/Header';
import HeaderTitle from '../components/HeaderTitle';
import Input from '../components/Input';
import Label from '../components/Label';
import Page from '../components/Page';
import Text from '../components/Text';
import analytics from '../functions/analyticsFunctions';

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleChangeLogin = this.handleChangeLogin.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.clearError();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user.name) {
      this.props.history.push('/');
    }
  }

  componentWillUnmount() {
    this.props.clearLogin();
  }

  handleChangeName(evt) {
    this.props.changeLoginName(capitalizeFirstLetter(evt.target.value));
  }

  handleChangeEmail(evt) {
    this.props.changeLoginEmail(capitalizeFirstLetter(evt.target.value));
  }

  handleChangePassword(evt) {
    this.props.changeLoginPassword(evt.target.value);
  }

  handleChangeLogin() {
    const { login, changeLoginState } = this.props;
    changeLoginState(!login.login);
  }

  handlePrivacy() {
    analytics.track('privacy');
    this.props.history.push('/privacy');
  }

  handleTerms() {
    analytics.track('terms');
    this.props.history.push('/terms');
  }

  handleSubmit(e) {
    e.preventDefault();
    let { user, login, loginUser, signupUser, loading, setError, setStatus } = this.props;

    if (loading) {
      return;
    }
    if (login.login) {
      if (!login.name && !login.password) {
        setError('Please enter your name/email and password');
        return;
      } else if (!login.name) {
        setError('Please enter your name or email');
        return;
      } else if (!login.password) {
        setError('Please enter your password');
        return;
      }
    } else {
      if (!login.name && !login.email && !login.password) {
        setError('Please enter your name, email, and password');
        return;
      } else if (!login.name) {
        setError('Please enter your name');
        return;
      } else if (!login.email) {
        setError('Please enter your email');
        return;
      } else if (!login.password) {
        setError('Please enter your password');
        return;
      } else if (!checkEmail(login.email)) {
        setError('Please enter your valid email');
        return;
      }
    }

    login._id = user._id;
    login.created = user.created;
    login.answered = user.answered;
    login.longestStreak = user.longestStreak;
    login.streak = user.streak;
    login.last = user.last;

    if (login.login) {
      loginUser(login);
      setStatus('Logged in');
    } else {
      signupUser(login);
      setStatus('Account created');
    }
  }

  render() {
    const { login, loading } = this.props;
    const loginButton = loading && login.name ? 'Loading...' : login.login ? 'Sign in' : 'Sign up';
    const policyButton = login.login ? 'Sign in' : 'Sign up';
    const switchButton = login.login ? 'Sign up' : 'Sign in';

    let loginState;
    if (login.login) {
      if (login.name && login.password) {
        loginState = true;
      } else {
        loginState = false;
      }
    } else {
      if (login.name && login.email && checkEmail(login.email) && login.password) {
        loginState = true;
      } else {
        loginState = false;
      }
    }

    return (
      <Page>
        <Header containerId="signup">
          <BackButton />
          <HeaderTitle>{login.login ? 'Sign in' : 'Sign up'}</HeaderTitle>
          <Button onClick={this.handleChangeLogin} id="switchLogin">
            {switchButton}
          </Button>
        </Header>
        <Container id="signup" paddingX="medium">
          <Form onSubmit={this.handleSubmit}>
            <Error />
            {!login.login && <Label>Name</Label>}
            {!login.login && (
              <Input
                type="text"
                value={login.name}
                onChange={this.handleChangeName}
                placeholder="Name"
                autoFocus
              />
            )}
            <Label>{!login.login ? 'Email' : 'Name'}</Label>
            <Input
              type="text"
              value={login.login ? login.name : login.email}
              onChange={e => (login.login ? this.handleChangeName(e) : this.handleChangeEmail(e))}
              placeholder={login.login ? 'Or email' : 'Email'}
              autoFocus
            />
            <Label>Password</Label>
            <Input
              type="password"
              value={login.password}
              onChange={this.handleChangePassword}
              placeholder="Password"
            />
            <Button
              backgroundColor={loginState ? 'purple' : 'backgroundGrey'}
              color={loginState ? 'white' : 'black'}
              disabled={!loginState}
              id="loginButton"
              marginTop="large"
              onClick={this.handleSubmit}
              width="full"
            >
              {loginButton}
            </Button>
          </Form>
          {!login.login && (
            <Box
              alignItems="center"
              justifyContent="center"
              color="grey"
              flexWrap="wrap"
              fontSize="small"
              padding="medium"
            >
              {`By pressing '${policyButton}', you agree with our `}
              <Text
                color="grey"
                fontSize="small"
                id="privacy"
                onClick={this.handlePrivacy}
                paddingX="xSmall"
              >
                Privacy Policy
              </Text>and
              <Text
                color="grey"
                fontSize="small"
                id="terms"
                onClick={this.handleTerms}
                paddingLeft="xSmall"
              >
                Terms of Service
              </Text>
            </Box>
          )}
        </Container>
      </Page>
    );
  }
}

LoginPage.propTypes = {
  history: PropTypes.object.isRequired,
  login: PropTypes.object.isRequired,
  changeLoginName: PropTypes.func.isRequired,
  changeLoginEmail: PropTypes.func.isRequired,
  changeLoginPassword: PropTypes.func.isRequired,
  changeLoginState: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  signupUser: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
  clearLogin: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = ({ user, login, loading }) => ({
  user,
  login,
  loading
});

LoginPage = connect(mapStateToProps, {
  changeLoginName,
  changeLoginEmail,
  changeLoginPassword,
  changeLoginState,
  signupUser,
  loginUser,
  clearLogin,
  setStatus,
  ...errorActions
})(LoginPage);

export default LoginPage;
