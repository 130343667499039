import { AiOutlineLineChart as growth } from 'react-icons/ai';
import { BsToggleOff as toggle, BsToggleOn as toggled } from 'react-icons/bs';
import { CgCalendarToday as today } from 'react-icons/cg';
import {
  MdSend as send,
  MdPerson as person,
  MdMail as mail,
  MdTrendingUp as trend
} from 'react-icons/md';
import {
  IoMdChatbubbles as chat,
  IoMdMenu as menu,
  IoIosCamera as camera,
  IoMdArrowRoundForward as forward,
  IoMdTrash as deleteIcon,
  IoMdClose as close,
  IoMdArrowRoundBack as back,
  IoIosInfinite as all,
  IoMdMore as more,
  IoMdCreate as pencil,
  IoMdAdd as plus,
  IoMdRemove as remove,
  IoMdSettings as settings,
  IoMdGlobe as world
} from 'react-icons/io';
import {
  FaHome as home,
  FaCircle as check,
  FaRegCircle as uncheck,
  FaHeart as heart,
  FaFacebook as facebook,
  FaStar as star,
  FaUserSecret as admin,
  FaInstagram as instagram,
  FaLinkedin as linkedin,
  // FaToggleOff as toggle,
  // FaToggleOn as toggled,
  FaTwitter as twitter
} from 'react-icons/fa';
import {
  FiAlertTriangle as alert,
  FiDownload as download,
  FiFileText as text,
  FiFlag as flag,
  FiLink as link,
  FiMail as email,
  FiShare as share,
} from 'react-icons/fi';
import { HiOutlineBriefcase as work } from 'react-icons/hi';
import { IoLibraryOutline as library } from 'react-icons/io5';
import { RiBookMarkLine as journal, RiUserLine as user } from 'react-icons/ri';
import { VscQuote as quote } from 'react-icons/vsc';

export default {
  admin,
  alert,
  all,
  back,
  camera,
  chat,
  check,
  close,
  delete: deleteIcon,
  download,
  email,
  facebook,
  star,
  flag,
  forward,
  growth,
  heart,
  home,
  instagram,
  journal,
  library,
  link,
  linkedin,
  mail,
  menu,
  more,
  pencil,
  person,
  plus,
  quote,
  remove,
  send,
  settings,
  share,
  text,
  today,
  toggle,
  toggled,
  trend,
  twitter,
  uncheck,
  user,
  work,
  world,
};
