import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';

import { borderRadii, boxShadow, colors, spaces, widths } from '../constants';
import { padding } from '../functions/styleFunctions';

const styles = ({ onClick, ...props }) => ({
  content: {
    background: colors.white,
    border: 'none',
    borderRadius: borderRadii.round,
    boxShadow,
    cursor: 'auto',
    display: 'flex',
    flexDirection: 'column',
    left: '50%',
    marginBottom: spaces.medium,
    maxWidth: widths.contain,
    outline: 'none',
    ...padding(props),
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85%',
    zIndex: 20
  },
  overlay: {
    backgroundColor: colors.white,
    bottom: 0,
    cursor: onClick ? 'pointer' : 'auto',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 15
  }
});

class Modal extends Component {
  constructor(props) {
    super(props);

    this.modalRoot = document.getElementById('modal-root');

    this.el = document.createElement('div');
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    this.modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    this.modalRoot.removeChild(this.el);
  }

  render() {
    const { children, ...props } = this.props;

    const style = styles(props);

    return ReactDOM.createPortal(
      <Fragment>
        <div className="modal-overlay" style={style.overlay} {...props}>
          <div
            className="modal-content"
            onClick={e => (e.stopPropagation ? e.stopPropagation() : (e.cancelBubble = true))}
            style={style.content}
          >
            {children}
          </div>
        </div>
      </Fragment>,
      this.el
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func
};

export default Modal;
