// src/modals/ShareModal.js

import PropTypes from 'prop-types';
import React from 'react';

import Container from '../components/Container';
import Header from '../components/Header';
import HeaderButton from '../components/HeaderButton';
import HeaderTitle from '../components/HeaderTitle';
import Icon from '../components/Icon';
import Modal from '../components/Modal';
import ShareContent from '../components/ShareContent';

const ShareModal = ({ answer, noWorld, onClose }) => (
  <Modal onClick={onClose}>
    <Header backgroundColor="transparent" padding="medium">
      <HeaderButton left onClick={onClose}>
        <Icon type="close" />
      </HeaderButton>
      <HeaderTitle fs="ml">Share</HeaderTitle>
      <HeaderButton hide />
    </Header>
    <Container backgroundColor="transparent" padding="medium">
      <ShareContent answer={answer} onClose={onClose} noWorld={noWorld} />
    </Container>
  </Modal>
);

ShareModal.defaultProps = {
  noWorld: false,
};

ShareModal.propTypes = {
  answer: PropTypes.object.isRequired,
  noWorld: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default ShareModal;
