import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const statusReducer = (state = initialState.status, action) => {
  switch (action.type) {
    case ActionTypes.SET_STATUS:
      return { ...state, message: action.message, warning: action.warning };

    case ActionTypes.CLEAR_STATUS:
      return initialState.status;

    default:
      return state;
  }
};

export default statusReducer;
