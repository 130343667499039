import PropTypes from 'prop-types';
import React from 'react';

import Box from '../components/Box';
import CancelButton from '../components/CancelButton';
import ConfirmButton from '../components/ConfirmButton';
import Modal from '../components/Modal';
import Title from '../components/Title';

const ConfirmModal = ({ handleClose, onClick, action }) => (
  <Modal onClick={handleClose} padding="medium">
    <Title color="darkGrey" padding="medium" textAlign="center">
      Are you sure you want to {action.toLowerCase()}?
    </Title>
    <Box noOverflow padding="medium" width="full">
      <CancelButton id="cancelButton" onClick={handleClose}>
        Cancel
      </CancelButton>
      <ConfirmButton id="confirmButton" onClick={onClick}>
        {action}
      </ConfirmButton>
    </Box>
  </Modal>
);

ConfirmModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired
};

export default ConfirmModal;
