// src/components/Icon.js

import PropTypes from 'prop-types';
import React from 'react';

import { colors, iconSizes } from '../constants';
import iconTypes from '../assets/icons';

const Icon = ({ color, size, type, alignItems, justifyContent, ...props }) => {
  const IconType = iconTypes[type];
  const iconSize = iconSizes[size];
  return <IconType color={colors[color || 'black']} size={iconSize} {...props} />;
};

Icon.propTypes = {
  rotate: PropTypes.number,
  size: PropTypes.string,
  type: PropTypes.string.isRequired
};

Icon.defaultProps = {
  alignItems: 'center',
  justifyContent: 'center',
  size: 'medium'
};

export default Icon;
