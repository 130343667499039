import ActionTypes from './actionTypes';

// set answer id
export const setAnswerId = answerId => ({
  type: ActionTypes.SET_ANSWER_ID,
  answerId
});

// set world answer id
export const setUserAnswerId = userAnswerId => ({
  type: ActionTypes.SET_USER_ANSWER_ID,
  userAnswerId
});

// set world answer id
export const setWorldAnswerId = worldAnswerId => ({
  type: ActionTypes.SET_WORLD_ANSWER_ID,
  worldAnswerId
});
