import PropTypes from 'prop-types';
import React from 'react';

import Icon from './Icon';

const CheckComponent = ({ picked }) => (
  <Icon color={picked ? 'green' : 'grey'} type={picked ? 'check' : 'uncheck'} />
);

CheckComponent.propTypes = {
  picked: PropTypes.bool
};

export default CheckComponent;
