const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const getToday = () => new Date();

export const getWeek = thisDate => {
  const date = new Date(thisDate);
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  const week1 = new Date(date.getFullYear(), 0, 4);
  return (
    1 +
    Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7)
  );
};

export const getChartDay = date => {
  date = new Date(date);
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export const getChartWeek = date => {
  date = new Date(date);
  return `${getWeek(date)}-${date.getFullYear()}`;
};

export const getChartMonth = date => {
  date = new Date(date);
  return `${date.getMonth() + 1}-${date.getFullYear()}`;
};

export const getChartYear = date => {
  date = new Date(date);
  return `${date.getFullYear()}`;
};

export const formattedDate = date => {
  date = new Date(date);
  return months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
};

export const formattedShortDate = date => {
  date = new Date(date);
  return months[date.getMonth()] + ' ' + date.getDate();
};

export const formattedToday = () => {
  const today = getToday();
  return formattedDate(today);
};

export const formattedShort = () => {
  const today = getToday();
  return formattedShortDate(today);
};

export const isToday = date => {
  date = new Date(date);
  const today = getToday();

  const dateDate = formattedDate(date);
  const todayDate = formattedDate(today);

  return todayDate === dateDate;
};

export const isYesterday = date => {
  date = new Date(date);
  let yesterday = getToday();
  yesterday.setDate(yesterday.getDate() - 1);

  const dateDate = formattedDate(date);
  const yesterdayDate = formattedDate(yesterday);

  return yesterdayDate === dateDate;
};

export const getAnswerDateStats = answers => {
  let thisWeek = 0;
  let thisMonth = 0;
  const allTime = answers.length;
  const today = new Date();
  const todayYear = today.getFullYear();
  const todayWeek = getWeek(today);
  const todayMonth = today.getMonth();
  const yearAnswers = answers.filter(a => {
    const answerDate = new Date(a.answered);
    return answerDate.getFullYear() === todayYear;
  });
  const thisYear = yearAnswers.length;
  yearAnswers.forEach(a => {
    const answerDate = new Date(a.answered);
    const isThisMonth = answerDate.getMonth() === todayMonth;
    const isThisWeek = getWeek(answerDate) === todayWeek;
    if (isThisWeek) thisWeek++;
    if (isThisMonth) thisMonth++;
  });

  return { allTime, thisWeek, thisYear, thisMonth };
};

export const isDayBeforeYesterday = date => {
  date = new Date(date);
  let dayBefore = getToday();
  dayBefore.setDate(dayBefore.getDate() - 2);

  const dateDate = formattedDate(date);
  const dayBeforeDate = formattedDate(dayBefore);

  return dayBeforeDate === dateDate;
};

export const isThisWeek = date => {
  const thisDate = new Date(date);
  const today = new Date();

  return getWeek(today) === getWeek(thisDate) && today.getFullYear() === thisDate.getFullYear();
};

export const isLastWeek = date => {
  const thisDate = new Date(date);
  const today = new Date();

  return getWeek(today) - 1 === getWeek(thisDate) && today.getFullYear() === thisDate.getFullYear();
};

export const isWeekBeforeLast = date => {
  const thisDate = new Date(date);
  const today = new Date();

  return getWeek(today) - 2 === getWeek(thisDate) && today.getFullYear() === thisDate.getFullYear();
};

export const isThisMonth = date => {
  const thisDate = new Date(date);
  const today = new Date();

  return thisDate.getMonth() === today.getMonth() && today.getFullYear() === thisDate.getFullYear();
};

export const isLastMonth = date => {
  const thisDate = new Date(date);
  const today = new Date();

  return (
    thisDate.getMonth() === today.getMonth() - 1 && today.getFullYear() === thisDate.getFullYear()
  );
};

export const isMonthBeforeLast = date => {
  const thisDate = new Date(date);
  const today = new Date();

  return (
    thisDate.getMonth() === today.getMonth() - 2 && today.getFullYear() === thisDate.getFullYear()
  );
};

export const isThisYear = date => {
  const thisDate = new Date(date);
  const today = new Date();

  return thisDate.getFullYear() === today.getFullYear();
};

export const isLastYear = date => {
  const thisDate = new Date(date);
  const today = new Date();

  return thisDate.getFullYear() === today.getFullYear() - 1;
};

export const isYearBeforeLast = date => {
  const thisDate = new Date(date);
  const today = new Date();

  return thisDate.getFullYear() === today.getFullYear() - 2;
};

export const sortByCreatedAt = (a, b) => {
  const dateA = new Date(a.createdAt);
  const dateB = new Date(b.createdAt);
  return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
};

export const sortByModified = (a, b) => {
  const dateA = new Date(a.modified);
  const dateB = new Date(b.modified);
  return dateA < dateB ? 1 : dateA > dateB ? -1 : 0;
};

export const sortBySent = (a, b) => {
  const dateA = new Date(a.sent);
  const dateB = new Date(b.sent);
  return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
};

export const reverseSortBySent = (a, b) => {
  const dateA = new Date(a.sent);
  const dateB = new Date(b.sent);
  return dateA < dateB ? 1 : dateA > dateB ? -1 : 0;
};
