// src/components/ShareContent.js

import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import * as answerActions from '../actions/answerActions';
import * as loginActions from '../actions/loginActions';
import * as statusActions from '../actions/statusActions';
import Button from '../components/Button';
import Card from '../components/Card';
import Icon from '../components/Icon';
import MediaItem from '../components/MediaItem';
import Toggle from '../components/Toggle';
import Text from '../components/Text';
import TextArea from '../components/TextArea';
import analytics from '../functions/analyticsFunctions';
import ConfirmModal from '../modals/ConfirmModal';
import ShareAsModal from '../modals/ShareAsModal';
import ShareMenuModal from '../modals/ShareMenuModal';

class ShareContent extends Component {
  constructor(props) {
    super(props);

    const { prompt_title, text } = props.answer;
    const hasText = !!text;
    let shareText;
    if (hasText) {
      shareText = `${prompt_title}\n\n${text}`;
    } else {
      shareText = prompt_title;
    }

    this.state = {
      hasText,
      includeAnswer: hasText,
      shareAnonymously: false,
      shareText: shareText,
      showShareMenuModal: false,
      showUnshareModal: false,
    };

    this.handleShareAnswer = this.handleShareAnswer.bind(this);
    this.handleShareMenuModal = this.handleShareMenuModal.bind(this);
    this.handleToggleAnonymously = this.handleToggleAnonymously.bind(this);
    this.handleToggleAnswer = this.handleToggleAnswer.bind(this);
    this.handleUnshareAnswer = this.handleUnshareAnswer.bind(this);
    this.shareText = this.shareText.bind(this);
    this.shareWorld = this.shareWorld.bind(this);
  }

  componentDidMount() {
    this.forceUpdate();
  }

  handleToggleAnonymously() {
    const { shareAnonymously } = this.state;

    const newShareAnonymously = !shareAnonymously;

    this.setState({ shareAnonymously: newShareAnonymously });

    analytics.track('toggle anonymous', { from: 'share modal' });
  }

  handleToggleAnswer() {
    const { answer } = this.props;
    const { includeAnswer } = this.state;

    const newIncludeAnswer = !includeAnswer;

    const { prompt_title, text } = answer;

    let shareText;
    if (newIncludeAnswer) {
      shareText = `${prompt_title}\n\n${text}`;
    } else {
      shareText = `${prompt_title}`;
    }

    this.setState({ includeAnswer: newIncludeAnswer, shareText });

    analytics.track('toggle answer', { from: 'share modal' });
  }

  shareText() {
    this.handleShareMenuModal();

    analytics.track('share text', { from: 'share modal' });
  }

  shareWorld() {
    const { answer, history, onClose, pickSignup, user } = this.props;

    if (answer.shared) {
      this.setState({ showUnshareModal: true });
      return;
    }

    if (!user.name) {
      pickSignup();
      onClose && onClose();
      history.push('/login');
      analytics.track('share world without profile', { from: 'share modal' });
      return;
    }

    this.handleShareAnswer();
  }

  handleShareAnswer() {
    const { answer, history, onClose, setStatus, shareAnswer, user } = this.props;
    const { shareAnonymously } = this.state;

    if (!shareAnonymously) {
      answer.profilePicture = user.profilePicture;
    }

    analytics.track('share answer', { anonymous: shareAnonymously });

    shareAnswer(answer, shareAnonymously);

    setStatus(shareAnonymously ? 'Answer shared Anonymously' : 'Answer shared successfully');

    history.push('/world');

    onClose && onClose();
  }

  handleUnshareAnswer() {
    const { answer, onClose, unshareAnswer } = this.props;
    unshareAnswer(answer._id);

    analytics.track('unshare', { from: 'share modal' });

    this.setState({ showUnshareModal: false });

    onClose && onClose();
  }

  handleShareMenuModal() {
    const { showShareMenuModal } = this.state;

    this.setState({ showShareMenuModal: !showShareMenuModal });
  }

  render() {
    const { answer, noWorld, onClose, user } = this.props;
    const {
      hasText,
      includeAnswer,
      shareAnonymously,
      shareText,
      showShareAsModal,
      showShareMenuModal,
      showUnshareModal,
    } = this.state;

    return (
      <Fragment>
        <Card marginBottom="medium">
          <MediaItem
            onClick={() => {
              // if (!answer.anonymous) {
              //   this.handleUser(answer.user_id);
              //   this.handleSetAnswerId(answer._id);
              // }
            }}
            src={!shareAnonymously && !noWorld ? user.profilePicture : null}
            title={
              shareAnonymously ? 'Anonymous' : answer.user_name ? answer.user_name : 'Anonymous'
            }
          />
          <TextArea
            noBackground
            value={shareText}
            paddingX="none"
            width="full"
          />
        </Card>
        {hasText && !noWorld && (
          <Toggle
            label="Share anonymously"
            onClick={this.handleToggleAnonymously}
            padding="small none"
            value={shareAnonymously}
          />
        )}
        {hasText && !noWorld && (
          <Button backgroundColor="purple" onClick={this.shareWorld} marginTop="medium" width="full">
            <Text color="white" paddingRight="small">{`${answer.shared ? 'Unshare with' : 'Share to'} Müse world`}</Text>
            <Icon color="white" type="world" />
          </Button>
        )}
        <Button marginTop="medium" onClick={this.shareText} width="full">
          <Text paddingRight="small">Share</Text>
          <Icon type="share" />
        </Button>
        {hasText && (
          <Toggle
            label="Include answer?"
            marginTop="medium"
            onClick={this.handleToggleAnswer}
            padding="small none"
            value={includeAnswer}
          />
        )}
        {showShareMenuModal && (
          <ShareMenuModal
            shareText={shareText.split('\n').join('%0D%0A')}
            handleClose={() => this.handleShareMenuModal()}
            handleClick={() => {
              this.handleShareMenuModal();
              onClose && onClose();
            }}
          />
        )}
        {showShareAsModal && (
          <ShareAsModal
            handleClose={() => {
              this.setState({ showShareAsModal: false });
              onClose && onClose();
            }}
            onClick={this.handleShareAnswer}
          />
        )}
        {showUnshareModal && (
          <ConfirmModal
            action="Unshare"
            handleClose={() => this.setState({ showUnshareModal: false })}
            onClick={this.handleUnshareAnswer}
          />
        )}
      </Fragment>
    );
  }
}

ShareContent.defaultProps = {
  noWorld: false,
  onClose: null,
};

ShareContent.propTypes = {
  answer: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  noWorld: PropTypes.bool,
  onClose: PropTypes.func,
  pickSignup: PropTypes.func.isRequired,
  shareAnswer: PropTypes.func.isRequired,
  unshareAnswer: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = ({ user }) => ({ user });

export default withRouter(connect(
  mapStateToProps,
  { ...answerActions, ...loginActions, ...statusActions },
)(ShareContent));
