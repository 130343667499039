import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const todayPromptDateReducer = (state = initialState.todayPromptDate, action) => {
  switch(action.type) {
    case ActionTypes.LOAD_TODAY_PROMPT_SUCCESS:
      return new Date();

    default:
      return state;
  }
};

export default todayPromptDateReducer;
