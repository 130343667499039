import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from '../App';
import configStore from '../store/configStore';
import '../stylesheets/dropzoneStyles.css';
import '../stylesheets/reactCodeStyles.css';
import '../stylesheets/styles.css';
import '../stylesheets/transitionsStyles.css';

const store = configStore();

ReactDOM.hydrate(
  <ReduxProvider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ReduxProvider>,
  document.getElementById('root')
);
