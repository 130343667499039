// src/store/configStore.js

import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import throttle from 'lodash/throttle';

import rootReducer from '../reducers';
import { loadState, saveState } from '../localstorage';

const configStore = () => {
  const persistedState = loadState();

  const middlewares = [thunk];
  //if (process.env.NODE_ENV !== 'production') {
  //middlewares.push(createLogger())
  //}

  const store = createStore(
    rootReducer,
    persistedState,
    composeWithDevTools(applyMiddleware(...middlewares))
  );

  store.subscribe(
    throttle(() => {
      saveState(store.getState());
    }, 1000)
  );

  return store;
};

export default configStore;
