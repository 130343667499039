// src/pages/CategoriesPage.js

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as answerActions from '../actions/answerActions';
import * as promptActions from '../actions/promptActions';
import * as userActions from '../actions/userActions';
import Box from '../components/Box';
import Button from '../components/Button';
import Container from '../components/Container';
import Header from '../components/Header';
import HeaderButton from '../components/HeaderButton';
import HeaderTitle from '../components/HeaderTitle';
import Icon from '../components/Icon';
import Page from '../components/Page';
import SideMenuButton from '../components/SideMenuButton';
import Status from '../components/Status';
import Text from '../components/Text';
import { ALL_PROMPTS, categoryIcons, spaceNumbers } from '../constants';
import analytics from '../functions/analyticsFunctions';
import { isToday } from '../functions/dateFunctions';
import { getContainerWidth, isDesktop } from '../functions/deviceFunctions';
import Answer from '../objects/Answer';

class CategoriesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasMounted: false,
    };

    this.handleRandom = this.handleRandom.bind(this);
    this.handleRandomCategory = this.handleRandomCategory.bind(this);
    this.handleRandomPrompt = this.handleRandomPrompt.bind(this);
  }

  componentDidMount() {
    this.props.loadTodayPrompt();

    this.setState({ hasMounted: true });
  }

  handleRandom() {
    const { history, loadCategory } = this.props;

    analytics.track('random', { from: 'categories' });

    loadCategory('');

    const prompt = this.handleRandomPrompt();

    loadCategory(ALL_PROMPTS);

    history.push(`/answer/${prompt._id}`);
  }

  handleRandomCategory(category) {
    const { history, loadCategory } = this.props;

    analytics.track(category, { from: 'categories' });

    loadCategory(category);

    const prompt = this.handleRandomPrompt();

    history.push(`/answer/${prompt._id}`);
  }

  handleRandomPrompt() {
    const { createNewAnswer, loadRandomPrompt, user } = this.props;

    const prompt = loadRandomPrompt();

    createNewAnswer(
      new Answer(user._id, user.name, prompt._id, prompt.title, prompt.type, prompt.categories)
    );

    return prompt;
  }

  render() {
    const { categories, history, todayPromptDate } = this.props;
    const { hasMounted } = this.state;

    let containerWidth = getContainerWidth();
    const desktop = isDesktop();
    containerWidth = containerWidth - ((desktop ? 4 : 3) * spaceNumbers.medium);
    let categoryWidth;
    if (desktop) {
      categoryWidth = containerWidth / 3;
    } else {
      categoryWidth = containerWidth / 2;
    }

    return hasMounted ? (
      <Page>
        <Status />
        <Header containerId="categories" zIndex={2}>
          <SideMenuButton />
          <HeaderTitle>Müse</HeaderTitle>
          <HeaderButton
            id="world"
            onClick={() => {
              history.push('/world');
              analytics.track('world', { from: 'categories' });
            }}
          >
            <Icon type="world" />
          </HeaderButton>
        </Header>
        <Container id="categories" padding="medium">
          <Box flexWrap="wrap">
            {/*<Label color="black">Select a category...</Label>*/}
            <Button
              backgroundColor={isToday(todayPromptDate) ? 'purple' : 'lightPurple'}
              flexDirection="column"
              id="today"
              marginBottom="medium"
              marginRight="medium"
              to="/answer/today"
              onClick={() => analytics.track('today', { from: 'categories' })}
              width={`${categoryWidth}px`}
              height={`${categoryWidth}px`}
            >
              <Icon color="white" type="today" size="xLarge" />
              <Text color="white" fontSize="xLarge" paddingTop="small">Daily</Text>
            </Button>
            <Button
              backgroundColor="green"
              flexDirection="column"
              id="create"
              marginBottom="medium"
              marginRight={desktop ? 'medium' : 'none'}
              to="/newPrompt"
              onClick={() => analytics.track('add', { from: 'categories' })}
              width={`${categoryWidth}px`}
              height={`${categoryWidth}px`}
            >
              <Icon color="white" type="plus" size="xLarge" />
              <Text color="white" fontSize="xLarge" paddingTop="small">Add</Text>
            </Button>
            <Button
              flexDirection="column"
              id="everything"
              marginBottom="medium"
              marginRight={desktop ? 'none' : 'medium'}
              onClick={this.handleRandom}
              width={`${categoryWidth}px`}
              height={`${categoryWidth}px`}
            >
              <Icon type="library" size="xLarge" />
              <Text fontSize="xLarge" paddingTop="small">All</Text>
            </Button>
            <Button
              flexDirection="column"
              id="today"
              marginBottom="medium"
              marginRight={desktop ? 'medium' : 'none'}
              to="/answer/journal"
              onClick={() => analytics.track('journal', { from: 'categories' })}
              width={`${categoryWidth}px`}
              height={`${categoryWidth}px`}
            >
              <Icon type="journal" size="xLarge" />
              <Text fontSize="xLarge" paddingTop="small">Journal</Text>
            </Button>
            {categories.map((category, index) => (
              <Button
                flexDirection="column"
                id={category}
                key={category}
                marginBottom="medium"
                marginRight={desktop ? (index + 2) % 3 === 0 ? 'none' : 'medium' : (index + 1) % 2 === 0 ? 'none' : 'medium'}
                onClick={() => this.handleRandomCategory(category)}
                width={`${categoryWidth}px`}
                height={`${categoryWidth}px`}
              >
                <Icon type={categoryIcons[category]} size="xLarge" />
                <Text fontSize="xLarge" paddingTop="small">{category}</Text>
              </Button>
            ))}
          </Box>
        </Container>
      </Page>
    ) : null;
  }
}

CategoriesPage.propTypes = {
  categories: PropTypes.array.isRequired,
  createNewAnswer: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  loadCategory: PropTypes.func.isRequired,
  loadRandomPrompt: PropTypes.func.isRequired,
  loadTodayPrompt: PropTypes.func.isRequired,
  todayPrompt: PropTypes.string.isRequired,
  todayPromptDate: PropTypes.any.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = ({ categories, todayPrompt, todayPromptDate, user }) => {

  return { user, categories, todayPrompt, todayPromptDate };
};

export default withRouter(
  connect(mapStateToProps, {
    ...answerActions,
    ...promptActions,
    ...userActions
  })(CategoriesPage)
);
