import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  changeNewPromptTitle,
  clearNewPrompt,
  createNewPrompt,
  loadCategory,
  pickNewPromptCategory
} from '../actions/promptActions';
import { createNewAnswer } from '../actions/answerActions';
import { setError } from '../actions/errorActions';
import BackButton from '../components/BackButton';
import Box from '../components/Box';
import Button from '../components/Button';
import Check from '../components/Check';
import Container from '../components/Container';
import Error from '../components/Error';
import Header from '../components/Header';
import HeaderButton from '../components/HeaderButton';
import HeaderTitle from '../components/HeaderTitle';
import Icon from '../components/Icon';
import Page from '../components/Page';
import Text from '../components/Text';
import TextArea from '../components/TextArea';
import Title from '../components/Title';
import { categoryIcons } from '../constants';
import analytics from '../functions/analyticsFunctions';
import { capitalizeFirstLetter } from '../functions/stringFunctions';
import Answer from '../objects/Answer';
import Prompt from '../objects/Prompt';

class NewPromptPage extends Component {
  constructor(props) {
    super(props);

    this.handleChangeText = this.handleChangeText.bind(this);
    this.checkCategory = this.checkCategory.bind(this);
    this.toggleCategory = this.toggleCategory.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentWillUnmount() {
    this.props.clearNewPrompt();
  }

  handleBack() {
    this.props.history.goBack();
  }

  handleChangeText(evt) {
    this.props.changeNewPromptTitle(capitalizeFirstLetter(evt.target.value));
  }

  checkCategory(category) {
    return this.props.newPrompt.categories.includes(category);
  }

  toggleCategory(category) {
    analytics.track(category, { from: 'new prompt' });
    this.props.pickNewPromptCategory(category);
  }

  handleSave() {
    const {
      user,
      newPrompt,
      createNewPrompt,
      createNewAnswer,
      setError,
      loadCategory
    } = this.props;
    if (!newPrompt.title) {
      setError('Please add a prompt');
      return;
    }
    if (newPrompt.categories.length === 0) {
      setError('Please select some categories');
      return;
    }
    analytics.track('create prompt', { from: 'new prompt' });
    const prompt = new Prompt(
      user._id,
      user.name,
      newPrompt.title,
      newPrompt.type,
      newPrompt.categories
    );
    loadCategory(newPrompt.categories[0]);
    createNewPrompt(prompt);
    createNewAnswer(
      new Answer(user._id, user.name, prompt._id.str, prompt.title, prompt.type, prompt.categories)
    );
    this.props.history.push(`/answer/${prompt._id}`);
  }

  loadAnswer() {
    document.getElementById('answer').focus();
  }

  render() {
    const { newPrompt, categories } = this.props;

    return (
      <Page>
        <Header>
          <BackButton />
          <HeaderTitle>Add a prompt</HeaderTitle>
          <HeaderButton
            id="saveNewPrompt"
            onClick={this.handleSave}
          >
            <Text>Add</Text>
          </HeaderButton>
        </Header>
        <Container zIndex={2}>
          <Error padding="medium" />
          <Box flexDirection="column" flexGrow={1} onClick={this.loadAnswer} paddingX="medium">
            <TextArea
              autoFocus
              fontSize="xLarge"
              id="answer"
              onChange={this.handleChangeText}
              placeholder="Type prompt here..."
              type="text"
              value={newPrompt.title}
            />
          </Box>
        </Container>
        <Header containerId="categoriesContainer" padding="medium">
          <Title color="grey" textAlign="center" width="full">
            Select categories
          </Title>
        </Header>
        <Container id="categoriesContainer" padding="medium">
          {categories.map(category => (
            <Button
              id={`toggle${category}`}
              justifyContent="spaceBetween"
              key={category}
              marginBottom="medium"
              onClick={() => this.toggleCategory(category)}
            >
              <Box alignItems="center">
                <Icon color={this.checkCategory(category) ? 'black' : 'grey'} type={categoryIcons[category]} />
                <Title color={this.checkCategory(category) ? 'black' : 'grey'} paddingLeft="medium">{category}</Title>
              </Box>
              <Check picked={this.checkCategory(category)} />
            </Button>
          ))}
          <HeaderButton hide />
        </Container>
      </Page>
    );
  }
}

NewPromptPage.propTypes = {
  categories: PropTypes.array.isRequired,
  changeNewPromptTitle: PropTypes.func.isRequired,
  clearNewPrompt: PropTypes.func.isRequired,
  createNewAnswer: PropTypes.func.isRequired,
  createNewPrompt: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  loadCategory: PropTypes.func.isRequired,
  newPrompt: PropTypes.object.isRequired,
  pickNewPromptCategory: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = ({ user, newPrompt, categories }) => ({
  categories,
  newPrompt,
  user
});

NewPromptPage = connect(mapStateToProps, {
  changeNewPromptTitle,
  clearNewPrompt,
  createNewAnswer,
  createNewPrompt,
  loadCategory,
  pickNewPromptCategory,
  setError
})(NewPromptPage);

export default NewPromptPage;
