import initialState from './initialState'
import ActionTypes from '../actions/actionTypes'

const editPromptsReducer = (state = initialState.editPrompts, action) => {
  switch(action.type) {

    case ActionTypes.LOAD_EDIT_PROMPTS:
      return JSON.parse(JSON.stringify(action.prompts))

    case ActionTypes.CHANGE_EDIT_PROMPTS:
      let editPromptIndex = state.findIndex(prompt => prompt._id === action.prompt._id)
      return [ ...state.slice(0, editPromptIndex), action.prompt, ...state.slice(editPromptIndex + 1) ]

    default:
      return state
  }
}

export default editPromptsReducer
