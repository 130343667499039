import axios from 'axios';
import ActionTypes from './actionTypes';
import { checkOffline } from './offlineActions';

// change new admin name
export const changeAdminName = name => ({ type: ActionTypes.CHANGE_ADMIN_NAME, name });

// add new admin
export const addAdmin = admin_name => dispatch => {
  dispatch({ type: ActionTypes.ADD_ADMIN, admin_name }); // add friend requested

  return axios.post('/api/admin/add', { admin_name });
};

// remove admin
export const removeAdmin = admin_name => dispatch => {
  dispatch({ type: ActionTypes.REMOVE_ADMIN, admin_name });

  return axios.post('/api/admin/remove', { admin_name });
};

// load admin
export const loadAdmin = () => (dispatch, getState) => {
  return axios.post('/api/admin').then(response => {
    dispatch({ type: ActionTypes.LOAD_ADMIN_SUCCESS, admin: response.data.admin });
    checkOffline(dispatch, getState);
  }, () => null); // load admin failed
};

// load admin answers
export const loadAdminAnswers = () => (dispatch, getState) => {
  return axios.post('/api/admin/answers').then(response => {
    dispatch({ type: ActionTypes.LOAD_ADMIN_ANSWERS_SUCCESS, answers: response.data.answers });
    checkOffline(dispatch, getState);
  }, () => null);
};

// load users
export const loadAdminUsers = () => (dispatch, getState) => {
  return axios.post('/api/admin/users').then(response => {
    dispatch({ type: ActionTypes.LOAD_ADMIN_USERS_SUCCESS, users: response.data.users });
    checkOffline(dispatch, getState);
  }, () => null);
};

// load prompts
export const loadAdminPrompts = () => (dispatch, getState) => {
  return axios.post('/api/admin/prompts').then(response => {
    dispatch({ type: ActionTypes.LOAD_ADMIN_PROMPTS_SUCCESS, prompts: response.data.prompts });
    checkOffline(dispatch, getState);
  }, () => null);
};

// clear data
export const clearData = () => ({ type: ActionTypes.CLEAR_DATA });
