import React from 'react';

import BackButton from '../components/BackButton';
import Box from '../components/Box';
import Button from '../components/Button';
import Container from '../components/Container';
import Header from '../components/Header';
import Icon from '../components/Icon';
import MediaItem from '../components/MediaItem';
import Page from '../components/Page';
import Text from '../components/Text';
import Title from '../components/Title';
import analytics from '../functions/analyticsFunctions';

const styles = {
  ios: {
    width: 'auto',
    minHeight: '50px',
    maxHeight: '50px',
    height: '50px',
    flexShrink: 0
  }
};

const AboutPage = () => (
  <Page>
    <Header containerId="about">
      <BackButton />
    </Header>
    <Container
      alignItems="center"
      id="about"
      itemScope
      itemType="http://schema.org/Organization"
      padding="medium"
    >
      <Title color="purple" component="h1" fontSize="32px" paddingTop="xLarge">
        What's <span itemProp="name">Müse?</span>
      </Title>
      <Text itemProp="description" paddingY="medium" textAlign="center">
        A space to reflect and learn about yourself
      </Text>
      <Text color="grey" fontSize="medium" paddingBottom="xLarge" textAlign="center">
        \myüz\ v. to think about something carefully and thoroughly
      </Text>
      <Title color="purple" fontSize="32px" paddingTop="xLarge">
        Why?
      </Title>
      <Text paddingY="medium">Increase awareness</Text>
      <Text>Strengthen EQ</Text>
      <Text paddingY="medium">Define goals</Text>
      <Text>Focus energy</Text>
      <Text paddingY="medium">Practice mindfulness</Text>
      <Text>Make conscious decisions</Text>
      <Text paddingY="medium">Increase confidence</Text>
      <Text>Overcome fears</Text>
      <Text paddingY="medium">Improve communication</Text>
      <Text>Enhance creativity</Text>
      <Text paddingTop="medium" paddingBottom="xLarge">Improve writing</Text>
      <Title color="purple" fontSize="32px" paddingTop="xLarge">
        How?
      </Title>
      <Text paddingBottom="medium" paddingTop="medium">
        1. Select a category
      </Text>
      <Text>2. Read the prompt</Text>
      <Text paddingY="medium">3. Reflect</Text>
      <Text paddingBottom="xLarge">You get out what you put in</Text>
      <Title color="purple" fontSize="32px" paddingTop="xLarge">
        Where?
      </Title>
      <Button
        margin="medium none xLarge"
        onClick={() => analytics.track('iOS app', { from: 'about' })}
        href="https://apps.apple.com/us/app/m%C3%BCse-daily-reflection-journal/id1237483776"
        itemProp="url"
        target="_blank"
      >
        <img
          alt="iOS app"
          id="ios"
          src="https://twos.s3.amazonaws.com/appStore.svg"
          title="iOS App"
          style={styles.ios}
        />
      </Button>
      <Title color="purple" fontSize="32px" marginBottom="medium" paddingTop="xLarge">
        Team
      </Title>
      <Button
        id="parker"
        itemProp="employee"
        marginBottom="medium"
        onClick={() => analytics.track('parker', { from: 'about' })}
        href="http://www.parkerklein.me"
        target="_blank"
      >
        <MediaItem
          itemProp="founder"
          src="https://twos.s3.amazonaws.com/parker_headshotcopy.jpg"
          title="Parker Klein"
          subTitle="Creator"
        />
      </Button>
      <Button
        id="joe"
        itemProp="employee"
        marginBottom="medium"
        onClick={() => analytics.track('joe', { from: 'about' })}
      >
        <MediaItem
          src="https://twos.s3.amazonaws.com/joe_headshot.jpg"
          title="Joe Steilberg"
          subTitle="Product"
        />
      </Button>
      <Button
        id="olivier"
        itemProp="employee"
        marginBottom="medium"
        href="https://www.olivierifrah.com"
        onClick={() => analytics.track('olivier', { from: 'about' })}
        target="_blank"
      >
        <MediaItem
          src="https://uploads-ssl.webflow.com/5e1689facb9d5168c0dcbe0b/5e6ee9ebe64d7d6c70db2411_image%201.png"
          title="Olivier Ifrah"
          subTitle="Branding"
        />
      </Button>
      <Button
        itemProp="employee"
        marginBottom="xLarge"
        onClick={() => analytics.track('makayla', { from: 'about' })}
      >
        <MediaItem
          src="https://twos.s3.amazonaws.com/1AEFD9A1-7FCA-4509-8526-A42BE7E1F996.jpeg"
          title="Makayla Robidoux"
          subTitle="Social Media Manager"
        />
      </Button>
      {/*
            <Title color="purple" fontSize="32px">
              Our Story
            </Title>
            <Text paddingBottom="medium" paddingTop="medium">
              Parker collected questions to learn about himself and what he wanted to do
            </Text>
            <Text>He loved introspection and wanted to share it with others</Text>
            <Text paddingY="medium">
              He told Olivier and Makayla about his vision of helping people reflect to learn more
              about themselves so they can do more of what they love
            </Text>
            <Text>They're helping people learn about themselves and do what they love</Text>
           */}
      <Title color="purple" fontSize="32px" paddingTop="xLarge">
        Contact
      </Title>
      <Text paddingY="medium" textAlign="center">
        We love stories, perspectives, and questions
      </Text>
      <Button
        color="purple"
        id="contact"
        itemProp="email"
        href="mailto:hi@reflectwithmuse.com?subject=Hi Müse"
        onClick={() => analytics.track('contact us', { from: 'about' })}
        target="_blank"
        marginBottom="xLarge"
        padding="large xxLarge"
      >
        Email
      </Button>
      <Title color="purple" fontSize="32px" paddingTop="xLarge">
        Rate
      </Title>
      <Text paddingY="medium" textAlign="center">
        Help rate Müse
      </Text>
      <Button
        color="purple"
        id="contact"
        itemProp="url"
        href="https://apps.apple.com/us/app/m%C3%BCse-daily-reflection-journal/id1237483776"
        onClick={() => analytics.track('rate', { from: 'about' })}
        target="_blank"
        marginBottom="xLarge"
        padding="large xxLarge"
      >
        Rate Müse
      </Button>
      <Title color="purple" fontSize="32px" paddingTop="xLarge">
        Jobs
      </Title>
      <Text paddingY="medium" textAlign="center">
        Interested in learning while helping build Müse?
      </Text>
      <Button
        color="purple"
        id="job"
        itemProp="email"
        href="mailto:hi@reflectwithmuse.com?subject=Job"
        target="_blank"
        marginBottom="xLarge"
        padding="large xxLarge"
        onClick={() => analytics.track('job', { from: 'about' })}
      >
        Email
      </Button>
      <Title color="purple" fontSize="32px" paddingTop="xLarge">
        Social
      </Title>
      <Box marginTop="medium" marginBottom="xLarge">
        <Button
          id="followTwitter"
          itemProp="url"
          href="https://twitter.com/reflectwithmuse"
          target="_blank"
          marginRight="small"
          padding="large"
          onClick={() => analytics.track('twitter', { from: 'about' })}
        >
          <Icon color="purple" type="twitter" />
        </Button>
        <Button
          id="followInstagram"
          itemProp="url"
          href="https://www.instagram.com/reflectwithmuse/"
          target="_blank"
          marginX="small"
          padding="large"
          onClick={() => analytics.track('instagram', { from: 'about' })}
        >
          <Icon color="purple" type="instagram" />
        </Button>
        <Button
          id="followFacebook"
          itemProp="url"
          marginLeft="small"
          href="https://www.facebook.com/reflectwithmuse/"
          target="_blank"
          padding="large"
          onClick={() => analytics.track('facebook', { from: 'about' })}
        >
          <Icon color="purple" type="facebook" />
        </Button>
      </Box>
      <Title color="purple" fontSize="32px" paddingTop="xLarge">
        Share
      </Title>
      <Box marginTop="medium" marginBottom="xLarge">
        <Button
          id="shareTwitter"
          itemProp="url"
          marginRight="small"
          href="http://twitter.com/share?text=Reflect with Müse\n\nwww.reflectwithmuse.com"
          target="_blank"
          padding="large"
          onClick={() => analytics.track('share twitter', { from: 'about' })}
        >
          <Icon color="purple" type="twitter" />
        </Button>
        <Button
          id="shareEmail"
          itemProp="url"
          href="mailto:?subject=Reflect with Müse&body=Reflect with Müse\n\nwww.reflectwithmuse.com"
          target="_blank"
          marginX="small"
          padding="large"
          onClick={() => analytics.track('share email', { from: 'about' })}
        >
          <Icon color="purple" type="mail" />
        </Button>
        <Button
          id="shareLinkedIn"
          itemProp="url"
          marginX="small"
          href="https://www.linkedin.com/shareArticle?mini=true&url=www.reflectwithmuse.com&title=Reflect with Müse&summary=&source=)"
          target="_blank"
          padding="large"
          onClick={() => analytics.track('share linkedin', { from: 'about' })}
        >
          <Icon color="purple" type="linkedin" />
        </Button>
        <Button
          id="shareFacebook"
          itemProp="url"
          href="http://www.facebook.com/share.php?u=www.reflectwithmuse.com"
          target="_blank"
          marginLeft="small"
          padding="large"
          onClick={() => analytics.track('share facebook', { from: 'about' })}
        >
          <Icon color="purple" type="facebook" />
        </Button>
      </Box>
      <Title color="purple" fontSize="32px" textAlign="center" paddingY="xLarge">
        Thank you for Müsing
      </Title>
    </Container>
  </Page>
);

export default AboutPage;
