// src/constants.js

export const CONTAINER_WIDTH = 900;

export const ALL_PROMPTS = 'All prompts';
export const JOURNAL = 'Journal';
export const TODAYS_PROMPT = "Today's prompt";

export const categoryIcons = {
  Gratitude: 'heart',
  Growth: 'growth',
  Life: 'home',
  Quotes: 'quote',
  Self: 'user',
  Today: 'today',
  Work: 'work',
};

export const alignments = {
  center: 'center',
  flexEnd: 'flex-end',
  flexStart: 'flex-start',
  spaceAround: 'space-around',
  spaceBetween: 'space-between',
  stretch: 'stretch'
};

export const borderRadii = {
  none: '0',
  square: '0',
  round: '16px',
  circle: '64px'
};

export const boxShadow = '0px 0px 8px 1px rgba(0, 0, 0, 0.2)';
export const boxShadowInset = `inset ${boxShadow}`;
export const footerBoxShadow = '0px -2px 8px 1px rgba(0, 0, 0, 0.2)';
export const headerBoxShadow = '0px 2px 8px 1px rgba(0, 0, 0, 0.2)';

export const colors = {
  backgroundGrey: '#f8f8f8',
  lightGrey: '#eee',
  black: '#333',
  darkGrey: '#777',
  green: '#66cc99',
  grey: '#aaa',
  purple: '#967adc',
  lightPurple: '#b6a2e7',
  red: 'lightcoral',
  transparent: 'transparent',
  white: '#fff'
};

export const fontSizes = {
  xxSmall: '10px',
  xSmall: '12px',
  small: '14px',
  medium: '16px',
  large: '18px',
  xLarge: '20px',
  xxLarge: '24px',
};

export const iconSizes = {
  small: 18,
  medium: 24,
  large: 36,
  xLarge: 48,
};

export const imageSizes = {
  medium: '40px',
  large: '56px'
};

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const museImage = 'https://twos.s3.amazonaws.com/Icon-60@3x.png';

export const spaces = {
  none: '0',
  // xxSmall: '2px',
  // xSmall: '4px',
  // small: '16px',
  // medium: '24px',
  // large: '32px'
  xxSmall: '2px',
  xSmall: '4px',
  small: '8px',
  medium: '16px',
  large: '24px',
  xLarge: '48px',
  xxLarge: '64px',
  xxxLarge: '128px'
};

export const spaceNumbers = {
  none: 0,
  // xxSmall: 2,
  // xSmall: 4,
  // small: 16,
  // medium: 24,
  // large: 32,
  xxSmall: 2,
  xSmall: 4,
  small: 8,
  medium: 16,
  large: 24,
  xLarge: 48,
  xxLarge: 64,
  xxxLarge: 128,
};

export const widths = {
  auto: 'auto',
  contain: `${CONTAINER_WIDTH}px`,
  half: '50%',
  full: '100%'
};
