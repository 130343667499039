import axios from 'axios';

import ActionTypes from './actionTypes';
import { ALL_PROMPTS, JOURNAL, TODAYS_PROMPT } from '../constants';
import analytics from '../functions/analyticsFunctions';
import { isToday } from '../functions/dateFunctions';
import { checkOffline } from './offlineActions';

// load category
export const loadCategory = category => dispatch => dispatch({ type: ActionTypes.LOAD_CATEGORY, category });

// edit new prompt title
export const changeNewPromptTitle = title => dispatch => dispatch({ type: ActionTypes.CHANGE_NEW_PROMPT_TITLE, title });

// add category to new prompt
export const pickNewPromptCategory = category => dispatch => dispatch({
  type: ActionTypes.PICK_NEW_PROMPT_CATEGORY,
  category
});

// create new prompt
export const createNewPrompt = prompt => (dispatch, getState) => {
  dispatch({ type: ActionTypes.CREATE_NEW_PROMPT, prompt }); // create new prompt

  analytics.track('create prompt');

  return axios
    .post('/api/prompt/create', { prompt })
    .then(
      () => checkOffline(dispatch, getState),
      () => dispatch({ type: ActionTypes.CREATE_NEW_PROMPT_OFFLINE, prompt })
    );
};

// clear new prompt
export const clearNewPrompt = () => dispatch => dispatch({ type: ActionTypes.CLEAR_NEW_PROMPT });

// edit prompt
export const editPrompt = prompt => dispatch => dispatch({ type: ActionTypes.EDIT_PROMPT, prompt });

// clean edit prompt
export const clearEditPrompt = () => dispatch => dispatch({ type: ActionTypes.CLEAR_EDIT_PROMPT });

// change edit prompt title
export const changeEditPromptTitle = title => dispatch => dispatch({
  type: ActionTypes.CHANGE_EDIT_PROMPT_TITLE,
  title
});

// pick category for edit prompt
export const pickEditPromptCategory = category => dispatch => dispatch({
  type: ActionTypes.PICK_EDIT_PROMPT_CATEGORY,
  category
});

// delete prompt
export const deletePrompt = prompt_id => dispatch => {
  dispatch({ type: ActionTypes.DELETE_PROMPT, prompt_id });

  return axios.post('/api/prompt/delete', { prompt_id });
};

// save edit prompt
export const saveEditPrompt = prompt => dispatch => {
  dispatch({ type: ActionTypes.SAVE_EDIT_PROMPT, prompt });

  return axios.post('/api/prompt/save', { prompt });
};

// clear new admin prompt
export const clearNewAdminPrompt = () => dispatch => dispatch({ type: ActionTypes.CLEAR_NEW_ADMIN_PROMPT });

// change new admin prompt title
export const changeNewAdminPromptTitle = title => dispatch => dispatch({
  type: ActionTypes.CHANGE_NEW_ADMIN_PROMPT_TITLE,
  title
});

// change new admin prompt category
export const pickNewAdminPromptCategory = category => dispatch => dispatch({
  type: ActionTypes.PICK_NEW_ADMIN_PROMPT_CATEGORY,
  category
});

// create new admin prompt
export const createNewAdminPrompt = prompt => dispatch => {
  dispatch({ type: ActionTypes.CREATE_NEW_ADMIN_PROMPT, prompt }); // send create user request

  return axios.post('/api/admin/prompt/create', { prompt });
};

// approve new prompt
export const approveNewPrompt = prompt => dispatch => {
  dispatch({ type: ActionTypes.APPROVE_NEW_PROMPT, prompt });

  return axios.post('/api/prompt/approve', { prompt_id: prompt._id });
};

// reject new prompt
export const rejectNewPrompt = prompt => dispatch => {
  dispatch({ type: ActionTypes.REJECT_NEW_PROMPT, prompt });

  return axios.post('/api/prompt/reject', { prompt_id: prompt._id });
};

// load approved prompts
export const loadApprovedPrompts = () => (dispatch, getState) => {
  dispatch({ type: ActionTypes.SET_LOADING });

  return axios.post('/api/prompts/approved').then(response => {
    dispatch({ type: ActionTypes.LOAD_APPROVED_PROMPTS_SUCCESS, prompts: response.data.prompts });
    checkOffline(dispatch, getState);
  }, () => null); // load approved prompts failed
};

// load user prompts
export const loadUserPrompts = _id => (dispatch, getState) => {
  return axios.post('/api/prompts/user', { _id }).then(response => {
    dispatch({ type: ActionTypes.LOAD_USER_PROMPTS_SUCCESS, prompts: response.data.prompts });
    checkOffline(dispatch, getState);
  }, () => null); // load user prompts failed
};

// load submitted prompts
export const loadSubmittedPrompts = () => (dispatch, getState) => {
  return axios.post('/api/prompts/submitted').then(response => {
    dispatch({
      type: ActionTypes.LOAD_SUBMITTED_PROMPTS_SUCCESS,
      prompts: response.data.prompts
    });
    checkOffline(dispatch, getState);
  }, () => null); // load submitted prompts failed
};

// load prompts into edit prompts
export const loadEditPrompts = prompts => dispatch => dispatch({ type: ActionTypes.LOAD_EDIT_PROMPTS, prompts });

// change edit prompts
export const changeEditPrompts = prompt => dispatch => dispatch({ type: ActionTypes.CHANGE_EDIT_PROMPTS, prompt });

// save edit prompts
export const saveEditPrompts = prompt => dispatch => {
  dispatch({ type: ActionTypes.SAVE_EDIT_PROMPTS, prompt });

  return axios.post('/api/prompt/save', { prompt });
};

export const loadRandomPrompt = () => (dispatch, getState) => {
  const { answer, answers, myPrompts, seen } = getState();
  let { category, prompts } = getState();

  // add my prompts to possible prompts
  prompts = (prompts || []).slice();
  (myPrompts || []).forEach(prompt => {
    if (!prompts.some(p => p._id === prompt._id)) {
      prompts.push(prompt);
    }
  });

  if (category === ALL_PROMPTS || category === TODAYS_PROMPT || category === JOURNAL) {
    category = '';
    loadCategory('')(dispatch, getState);
  }

  // take out current prompt
  const otherPrompts = prompts.filter(p => p._id !== answer.prompt_id);
  let availablePrompts = otherPrompts;

  let categoryPrompts;
  // if we have a category, filter by category
  if (category) {
    // only current category prompts
    categoryPrompts = availablePrompts.filter(p => p.categories.some(c => c === category));
    availablePrompts = categoryPrompts;
  }

  // take out seen prompts from today
  availablePrompts = availablePrompts.filter(p => !seen.prompts.some(s => s === p._id));

  // take out previously answered prompts
  availablePrompts = availablePrompts.filter(p => !answers.some(a => a.prompt_id === p._id));

  // if less than 10 available prompts, show all category prompts
  if (availablePrompts.length < 10) {
    availablePrompts = category ? categoryPrompts : otherPrompts;
  }

  const prompt = availablePrompts[Math.floor(Math.random() * availablePrompts.length)];

  return prompt;
};

export const loadTodayPrompt = () => (dispatch, getState) => {
  const { todayPromptDate } = getState();

  if (isToday(todayPromptDate)) return;

  const prompt = loadRandomPrompt()(dispatch, getState);

  if (!prompt) return;

  dispatch({ type: ActionTypes.LOAD_TODAY_PROMPT_SUCCESS, prompt_id: prompt._id });
};

export const pickToday = prompt_id => dispatch => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const day = now.getDate();

  return axios
    .post('/api/prompt/today/update', { prompt_id, year, month, day })
    .then(() => dispatch({ type: ActionTypes.UPDATE_TODAY_PROMPT_SUCCESS, prompt_id }), () => null);
};

// export const printPrompts = () => (dispatch) => {
//   return axios.post('/api/prompt/print')
// }
