import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const errorReducer = (state = initialState.error, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_USER_FAIL:
    case ActionTypes.LOGIN_USER_FAIL:
    case ActionTypes.SAVE_EDIT_USER_FAIL:
    case ActionTypes.SET_ERROR:
    case ActionTypes.SIGNUP_USER_FAIL:
      return action.error;

    case ActionTypes.CHANGE_EDIT_PROMPT_TITLE:
    case ActionTypes.CHANGE_LOGIN_EMAIL:
    case ActionTypes.CHANGE_LOGIN_NAME:
    case ActionTypes.CHANGE_LOGIN_PASSWORD:
    case ActionTypes.CHANGE_LOGIN_STATE:
    case ActionTypes.CHANGE_NEW_ADMIN_PROMPT_TITLE:
    case ActionTypes.CHANGE_NEW_PROMPT_TITLE:
    case ActionTypes.CLEAR_EDIT_PROMPT:
    case ActionTypes.CLEAR_ERROR:
    case ActionTypes.CLEAR_LOGIN:
    case ActionTypes.CLEAR_NEW_ADMIN_PROMPT:
    case ActionTypes.CLEAR_NEW_PROMPT:
    case ActionTypes.LOGIN_USER_REQUEST:
    case ActionTypes.PICK_EDIT_PROMPT_CATEGORY:
    case ActionTypes.PICK_NEW_ADMIN_PROMPT_CATEGORY:
    case ActionTypes.PICK_NEW_PROMPT_CATEGORY:
    case ActionTypes.PICK_USER_CATEGORY:
    case ActionTypes.SAVE_EDIT_USER_REQUEST:
    case ActionTypes.SIGNUP_USER_REQUEST:
      return '';

    default:
      return state;
  }
};

export default errorReducer;
