import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const myPromptsReducer = (state = initialState.myPrompts, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_USER_PROMPTS_SUCCESS:
      return action.prompts;

    case ActionTypes.CREATE_NEW_PROMPT:
      const createdPrompt = action.prompt;
      const newPrompt = {
        _id: createdPrompt._id.str,
        categories: createdPrompt.categories,
        created: createdPrompt.created,
        status: createdPrompt.status,
        title: createdPrompt.title,
        type: createdPrompt.type,
        user_id: createdPrompt.user_id,
        user_name: createdPrompt.user_name
      };
      return [...state, newPrompt];

    case ActionTypes.CREATE_NEW_ADMIN_PROMPT:
      return [...state, action.prompt];

    case ActionTypes.SAVE_EDIT_PROMPT:
      let editPromptIndex = state.findIndex(prompt => prompt._id === action.prompt._id);
      return editPromptIndex === -1
        ? state
        : [...state.slice(0, editPromptIndex), action.prompt, ...state.slice(editPromptIndex + 1)];

    case ActionTypes.DELETE_PROMPT:
      let deletePromptIndex = state.findIndex(prompt => prompt._id === action.prompt_id);
      return deletePromptIndex === -1
        ? state
        : [...state.slice(0, deletePromptIndex), ...state.slice(deletePromptIndex + 1)];

    case ActionTypes.SIGNUP_USER_SUCCESS:
      let signupPrompts = state.slice();
      signupPrompts.forEach(prompt => {
        prompt.user_name = action.user.name;
      });
      return signupPrompts;

    case ActionTypes.LOGIN_USER_SUCCESS:
      let loginPrompts = state.slice();
      loginPrompts.forEach(prompt => {
        prompt.user_id = action.user._id;
        prompt.user_name = action.user.name;
      });
      return loginPrompts;

    case ActionTypes.LOGOUT:
      return initialState.myPrompts;

    case ActionTypes.OFFLINE_SAVE_SUCCESS:
      return action.myPrompts;

    default:
      return state;
  }
};

export default myPromptsReducer;
