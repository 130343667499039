import axios from 'axios';
import ActionTypes from './actionTypes';

export const checkOffline = (dispatch, getState) => {
  const { user, offline } = getState();

  if (offline.beenOffline) {
    dispatch({ type: ActionTypes.OFFLINE_SAVE });

    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const day = now.getDate();

    return axios
      .post('/api/offline/save', { user_id: user._id, offline: offline, year, month, day })
      .then(
        response =>
          dispatch({
            type: ActionTypes.OFFLINE_SAVE_SUCCESS,
            user: response.data.user,
            answers: response.data.answers,
            myPrompts: response.data.myPrompts
          }),
        () => dispatch({ type: ActionTypes.OFFLINE_SAVE_FAIL })
      );
  }
};

// export const wasOffline = (user_id, offline) => (dispatch) => {
//   dispatch({ type: ActionTypes.OFFLINE_SAVE })
//
//   return axios.post('/api/offline/save', { user_id, offline }).then(
//     response => dispatch({ type: ActionTypes.OFFLINE_SAVE_SUCCESS, user: response.data.user, answers: response.data.answers, myPrompts: response.data.myPrompts }), // save offline success
//     error => dispatch({ type: ActionTypes.OFFLINE_SAVE_FAIL })
//   )
// }
