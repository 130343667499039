import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Text from './Text';
import { borderRadii, boxShadow, colors, spaces, widths } from '../constants';

const styles = ({ warning }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  maxWidth: widths.contain,
  width: 'calc(100% - 32px)',
  margin: `${spaces.small} auto`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: colors[warning ? 'red' : 'green'],
  borderRadius: borderRadii.circle,
  boxShadow,
  zIndex: 30,
  pointerEvents: 'none',
  padding: spaces.small
});

const Status = ({ dispatch, status: { message, warning }, ...props }) =>
  !!message && (
    <div className="status" style={styles({ dispatch, warning, ...props })} {...props}>
      <Text color="white" textAlign="center">
        {message}
      </Text>
    </div>
  );

Status.propTypes = {
  dispatch: PropTypes.any,
  status: PropTypes.object
};

const mapStateToProps = ({ status }) => ({ status });

export default connect(mapStateToProps)(Status);
