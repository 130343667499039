import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const loadingReducer = (state = initialState.loading, action) => {
  switch (action.type) {
    case ActionTypes.SIGNUP_USER_REQUEST:
    case ActionTypes.LOGIN_USER_REQUEST:
    case ActionTypes.SET_LOADING:
    case ActionTypes.SAVE_EDIT_USER_REQUEST:
      return true;

    case ActionTypes.SIGNUP_USER_SUCCESS:
    case ActionTypes.SIGNUP_USER_FAIL:
    case ActionTypes.LOGIN_USER_SUCCESS:
    case ActionTypes.LOGIN_USER_FAIL:
    case ActionTypes.LOAD_APPROVED_PROMPTS_SUCCESS:
    case ActionTypes.SAVE_EDIT_USER_FAIL:
    case ActionTypes.SAVE_EDIT_USER_SUCCESS:
      return false;

    default:
      return state;
  }
};

export default loadingReducer;
