import React from 'react';

import Button from './Button';

const CancelButton = props => <Button className="cancel-button" {...props} />;

CancelButton.defaultProps = {
  flexBasis: '50%',
  flexShrink: 1,
  marginRight: 'small'
};

export default CancelButton;
