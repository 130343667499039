import axios from 'axios';
import ActionTypes from './actionTypes';
import { checkOffline } from './offlineActions';

// create new answer
export const createNewAnswer = answer => ({ type: ActionTypes.CREATE_NEW_ANSWER, answer });

// save answer text
export const saveAnswer = answer => (dispatch, getState) => {
  dispatch({ type: ActionTypes.SAVE_ANSWER, answer }); // save answer

  return axios
    .post('/api/answer/save', { answer })
    .then(
      () => checkOffline(dispatch, getState),
      () => dispatch({ type: ActionTypes.SAVE_ANSWER_OFFLINE, answer })
    );
};

// Load answer
export const loadAnswer = answer => ({ type: ActionTypes.LOAD_ANSWER, answer });

// change answer text
export const changeAnswerText = text => ({ type: ActionTypes.CHANGE_ANSWER_TEXT, text });

// delete answer
export const deleteAnswer = answer_id => (dispatch, getState) => {
  dispatch({ type: ActionTypes.DELETE_ANSWER, answer_id });

  return axios
    .post('/api/answer/delete', { answer_id })
    .then(
      () => checkOffline(dispatch, getState),
      () => dispatch({ type: ActionTypes.DELETE_ANSWER_OFFLINE, answer_id })
    );
};

// share answer
export const shareAnswer = (answer, anonymous) => (dispatch, getState) => {
  dispatch({ type: ActionTypes.SHARE_ANSWER, answer, anonymous });

  return axios
    .post('/api/answer/share', { answer_id: answer._id, anonymous, user_id: answer.user_id })
    .then(() => checkOffline(dispatch, getState));
};

// unshare answer
export const unshareAnswer = answer_id => (dispatch, getState) => {
  dispatch({ type: ActionTypes.UNSHARE_ANSWER, answer_id });

  return axios
    .post('/api/answer/unshare', { answer_id })
    .then(() => checkOffline(dispatch, getState));
};

// like answer
export const likeAnswer = (answer_id, user_id) => (dispatch, getState) => {
  dispatch({ type: ActionTypes.LIKE_ANSWER, answer_id, user_id });

  return axios
    .post('/api/answer/like', { answer_id, user_id })
    .then(() => checkOffline(dispatch, getState));
};

// unlike answer
export const unlikeAnswer = (answer_id, user_id) => (dispatch, getState) => {
  dispatch({ type: ActionTypes.UNLIKE_ANSWER, answer_id, user_id });

  return axios
    .post('/api/answer/unlike', { answer_id, user_id })
    .then(() => checkOffline(dispatch, getState));
};

// load shared answers
export const loadSharedAnswers = page => (dispatch, getState) => {
  dispatch({ type: ActionTypes.LOAD_SHARED_ANSWERS_REQUEST, page });

  return axios.post('/api/answer/shared', { page }).then(response => {
    dispatch({
      type: ActionTypes.LOAD_SHARED_ANSWERS_SUCCESS,
      sharedAnswers: response.data.sharedAnswers,
      page
    });
    checkOffline(dispatch, getState);
  }, () => null); // load approved prompts failed
};

// report answer
export const reportAnswer = answer_id => (dispatch, getState) => {
  dispatch({ type: ActionTypes.REPORT_ANSWER, answer_id });

  return axios
    .post('/api/answer/report', { answer_id })
    .then(() => checkOffline(dispatch, getState));
};

// unreport answer
export const unreportAnswer = answer_id => (dispatch, getState) => {
  dispatch({ type: ActionTypes.UNREPORT_ANSWER, answer_id });

  return axios
    .post('/api/answer/unreport', { answer_id })
    .then(() => checkOffline(dispatch, getState));
};

// load reported answers
export const loadReportedAnswers = answer_id => (dispatch, getState) => {
  return axios
    .get('/api/answer/reported')
    .then(response => {
      dispatch({
        type: ActionTypes.LOAD_REPORTED_ANSWERS_SUCCESS,
        reportedAnswers: response.data.reportedAnswers,
      });
      checkOffline(dispatch, getState);
    });
};
