import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const submittedPromptsReducer = (state = initialState.submittedPrompts, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_NEW_ADMIN_PROMPT:
      return [...state, action.prompt];

    case ActionTypes.CREATE_NEW_PROMPT:
      return [...state, action.prompt];

    case ActionTypes.SAVE_EDIT_PROMPT:
      let editPromptIndex = state.findIndex(prompt => prompt._id === action.prompt._id);
      return editPromptIndex === -1
        ? state
        : [...state.slice(0, editPromptIndex), action.prompt, ...state.slice(editPromptIndex + 1)];

    case ActionTypes.DELETE_PROMPT:
      let deletePromptIndex = state.findIndex(prompt => prompt._id === action.prompt_id);
      return deletePromptIndex === -1
        ? state
        : [...state.slice(0, deletePromptIndex), ...state.slice(deletePromptIndex + 1)];

    case ActionTypes.APPROVE_NEW_PROMPT:
      let approveNewPromptIndex = state.findIndex(prompt => prompt._id === action.prompt._id);
      return [...state.slice(0, approveNewPromptIndex), ...state.slice(approveNewPromptIndex + 1)];

    case ActionTypes.REJECT_NEW_PROMPT:
      let rejectNewPromptIndex = state.findIndex(prompt => prompt._id === action.prompt._id);
      return [...state.slice(0, rejectNewPromptIndex), ...state.slice(rejectNewPromptIndex + 1)];

    case ActionTypes.SIGNUP_USER_SUCCESS:
      let signupPrompts = state.slice();
      signupPrompts.forEach(prompt => {
        if (prompt.user_id === action.user._id) {
          prompt.user_name = action.user.name;
        }
      });
      return signupPrompts;

    case ActionTypes.LOGIN_USER_SUCCESS:
      let loginPrompts = state.slice();
      loginPrompts.forEach(prompt => {
        if (prompt.user_id === action.user.old_id) {
          prompt.user_id = action.user._id;
          prompt.user_name = action.user.name;
        }
      });
      return loginPrompts;

    case ActionTypes.LOAD_SUBMITTED_PROMPTS_SUCCESS:
      return action.prompts;

    default:
      return state;
  }
};

export default submittedPromptsReducer;
