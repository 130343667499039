import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactCodeInput from 'react-code-input';
import { connect } from 'react-redux';

import { clearError } from '../actions/errorActions';
import Box from '../components/Box';
import Error from '../components/Error';
import Header from '../components/Header';
import HeaderButton from '../components/HeaderButton';
import HeaderTitle from '../components/HeaderTitle';
import Modal from '../components/Modal';
import Button from '../components/Button';
import Icon from '../components/Icon';
import analytics from '../functions/analyticsFunctions';

class CodeModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: ''
    };
  }

  render() {
    const { handleClose, noClose, onFinish, user } = this.props;
    const { code } = this.state;

    return (
      <Modal onClick={handleClose}>
        <Header backgroundColor="transparent" padding="medium">
          {!noClose ? (
            <HeaderButton onClick={handleClose} left>
              <Icon type="close" />
            </HeaderButton>
          ) : (
            <HeaderButton hide />
          )}
          <HeaderTitle>Enter Passcode</HeaderTitle>
          <HeaderButton hide />
        </Header>
        <Error paddingBottom="medium" />
        <Box alignItems="center" flexDirection="column" padding="large medium medium">
          <ReactCodeInput
            type={noClose ? 'password' : 'tel'}
            fields={4}
            onChange={val => {
              this.props.clearError();
              if (val.length === 4) {
                this.setState({ code: val });
                onFinish(val);
              } else {
                this.setState({ code: val });
              }
            }}
            value={code}
          />
          {noClose ? (
            <Button
              color="purple"
              justifyContent="center"
              id="helpCode"
              marginTop="medium"
              href={`mailto:help@reflectwithmuse.com?subject=Help! I forgot my passcode&body=My username is ${user.name}`}
              target="_blank"
              onClick={() => analytics.track('contact us', { from: 'code' })}
            >
              Need Help?
            </Button>
          ) : (
            <Box padding="medium" />
          )}
        </Box>
      </Modal>
    );
  }
}

CodeModal.propTypes = {
  clearError: PropTypes.func,
  handleClose: PropTypes.func,
  noClose: PropTypes.bool,
  onFinish: PropTypes.func.isRequired,
  user: PropTypes.object
};

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps, {
  clearError
})(CodeModal);
