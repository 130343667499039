// src/containers/SideMenu.js

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as loginActions from '../actions/loginActions';
import * as sideMenuActions from '../actions/sideMenuActions';
import * as userActions from '../actions/userActions';
import Header from '../components/Header';
import HeaderButton from '../components/HeaderButton';
import Icon from '../components/Icon';
import Container from '../components/Container';
import Box from '../components/Box';
import Button from '../components/Button';
import Img from '../components/Img';
import Text from '../components/Text';
import Title from '../components/Title';
import { boxShadow, colors } from '../constants';
import analytics from '../functions/analyticsFunctions';
import { formattedDate } from '../functions/dateFunctions';
import { download } from '../functions/downloadFunctions';

const styles = {
  sideMenu: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    width: '100%',
    zIndex: 10,
    display: 'flex'
  },
  sideMenuContent: {
    width: '400px',
    height: '100%',
    backgroundColor: colors.white,
    boxShadow
  },
  sideMenuPage: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    backgroundColor: colors.white
  },
  sideMenuClose: {
    flexGrow: 1
  },
  ios: {
    width: 'auto',
    minHeight: '50px',
    maxHeight: '50px',
    height: '50px',
    flexShrink: 0
  }
};

class SideMenu extends Component {
  constructor(props) {
    super(props);

    this.handleSignup = this.handleSignup.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  handleSignup() {
    const { pickSignup, toggleSideMenu } = this.props;

    analytics.track('signup', { from: 'side menu' });

    pickSignup();
    toggleSideMenu();
  }

  handleLogin() {
    const { pickLogin, toggleSideMenu } = this.props;

    analytics.track('login', { from: 'side menu' });

    pickLogin();
    toggleSideMenu();
  }

  render() {
    const {
      admin,
      answers,
      location,
      sideMenu,
      toggleSideMenu,
      user: { name, profilePicture, started }
    } = this.props;

    const { pathname } = location;

    return (
      sideMenu && (
        <div style={styles.sideMenu}>
          <div style={styles.sideMenuContent}>
            <div style={styles.sideMenuPage}>
              <Header containerId="sideMenuContainer">
                <HeaderButton id="closeSideMenu" onClick={toggleSideMenu}>
                  <Icon type="close" />
                </HeaderButton>
              </Header>
              <Container id="sideMenuContainer" padding="medium" paddingBottom="xLarge">
                {!name && (
                  <Box marginBottom="medium">
                    <Button
                      flexGrow={1}
                      id="loginHeader"
                      onClick={this.handleLogin}
                      to="/login"
                      marginRight="medium"
                    >
                      Sign in
                    </Button>
                    <Button
                      backgroundColor="purple"
                      color="white"
                      flexGrow={1}
                      id="signupHeader"
                      onClick={this.handleSignup}
                      to="/login"
                    >
                      Sign up
                    </Button>
                  </Box>
                )}
                {!!name && (
                  <Button
                    id="userProfile"
                    flexDirection="column"
                    marginBottom="medium"
                    noShadow
                    onClick={() => {
                      analytics.track('profile', { from: 'side menu' });
                      toggleSideMenu();
                    }}
                    to="/userProfile"
                    paddingY="none"
                  >
                    <Img src={profilePicture} size="large" />
                    <Title paddingTop="small" textAlign="center">
                      {name}
                    </Title>
                  </Button>
                )}
                {/*<Button
                  id="muse"
                  color={pathname === '/' ? 'grey' : 'black'}
                  marginBottom="medium"
                  onClick={toggleSideMenu}
                  to="/"
                >
                  Müse
                </Button>
                <Button
                  id="world"
                  color={pathname === '/world' ? 'grey' : 'black'}
                  marginBottom="medium"
                  onClick={toggleSideMenu}
                  to="/world"
                >
                  World
                </Button>
                <Button
                  id="createAPrompt"
                  backgroundColor={pathname === '/newPrompt' ? 'backgroundGrey' : 'purple'}
                  color={pathname === '/newPrompt' ? 'grey' : 'white'}
                  marginBottom="medium"
                  onClick={toggleSideMenu}
                  to="/newPrompt"
                >
                  Add a prompt
                </Button>*/}
                <Button
                  id="myAnswers"
                  color={pathname === '/past' ? 'grey' : 'black'}
                  marginBottom="medium"
                  onClick={() => {
                    analytics.track('answers', { from: 'side menu' });
                    toggleSideMenu();
                  }}
                  to="/past"
                >
                  My answers
                </Button>
                <Button
                  id="myProfile"
                  color={pathname === '/userProfile' ? 'grey' : 'black'}
                  marginBottom="medium"
                  onClick={() => {
                    analytics.track('profile', { from: 'side menu' });
                    toggleSideMenu();
                  }}
                  to="/userProfile"
                >
                  My profile
                </Button>
                {admin.some(a => a.name === name) && (
                  <Button
                    id="admin"
                    color={pathname === '/managePrompts' ? 'grey' : 'black'}
                    marginBottom="medium"
                    onClick={() => {
                      analytics.track('admin', { from: 'side menu' });
                      toggleSideMenu();
                    }}
                    to="/managePrompts"
                  >
                    Admin
                  </Button>
                )}
                {answers.length > 0 && (
                  <Button
                    id="downloadAnswers"
                    marginBottom="medium"
                    onClick={() => {
                      analytics.track('download', { from: 'side menu' });
                      download(answers);
                    }}
                    title="Download your answers"
                  >
                    Download my answers
                  </Button>
                )}
                <Button
                  id="contactMuse"
                  marginBottom="medium"
                  onClick={() => analytics.track('contact us', { from: 'side menu' })}
                  href="mailto:hi@reflectwithmuse.com?subject=Hi Müse"
                  itemProp="url"
                  target="_blank"
                >
                  Contact us
                </Button>
                <Button
                  id="shareMuse"
                  marginBottom="medium"
                  onClick={() => analytics.track('share muse', { from: 'side menu' })}
                  href="mailto:?subject=Reflect with Müse&body=www.reflectwithmuse.com"
                  itemProp="url"
                  target="_blank"
                >
                  Share Müse
                </Button>
                <Button
                  id="rateMuse"
                  marginBottom="medium"
                  onClick={() => analytics.track('rate', { from: 'side menu' })}
                  href="https://apps.apple.com/us/app/m%C3%BCse-daily-reflection-journal/id1237483776"
                  itemProp="url"
                  target="_blank"
                >
                  Rate Müse
                </Button>
                {/*<Button
                  id="donateMuse"
                  marginBottom="medium"
                  href="https://www.buymeacoffee.com/xJUZB6b"
                  itemProp="url"
                  target="_blank"
                >
                  Donate
                </Button>*/}
                <Button
                  backgroundColor="purple"
                  color="white"
                  id="whatsMuseSideMenu"
                  onClick={() => {
                    analytics.track('what', { from: 'side menu' });
                    toggleSideMenu();
                  }}
                  to="/about"
                >
                  What's Müse and<br />why should we use it?
                </Button>
                <Button
                  id="download"
                  noShadow
                  onClick={() => analytics.track('iOS app', { from: 'side menu' })}
                  href="https://apps.apple.com/us/app/m%C3%BCse-daily-reflection-journal/id1237483776"
                  itemProp="url"
                  target="_blank"
                >
                  <img
                    alt="iOS app"
                    id="ios"
                    src="https://twos.s3.amazonaws.com/appStore.svg"
                    style={styles.ios}
                    title="iOS app"
                  />
                </Button>
                <Text padding="medium" textAlign="center">
                  Müsing since {formattedDate(started)}
                </Text>
                <Box padding="medium" />
              </Container>
            </div>
          </div>
          <div style={styles.sideMenuClose} onClick={toggleSideMenu} />
        </div>
      )
    );
  }
}

SideMenu.propTypes = {
  admin: PropTypes.array.isRequired,
  answers: PropTypes.array.isRequired,
  loadOtherUser: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  pickLogin: PropTypes.func.isRequired,
  pickSignup: PropTypes.func.isRequired,
  sideMenu: PropTypes.bool.isRequired,
  toggleSideMenu: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = ({ admin, answers, sideMenu, user }) => ({
  admin,
  answers,
  sideMenu,
  user
});

export default withRouter(
  connect(mapStateToProps, { ...userActions, ...loginActions, ...sideMenuActions })(SideMenu)
);
