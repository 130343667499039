import axios from 'axios';
import ActionTypes from './actionTypes';
import { checkOffline } from './offlineActions';
import analytics from '../functions/analyticsFunctions';

// create user
export const createUser = user => (dispatch, getState) => {
  dispatch({ type: ActionTypes.CREATE_USER_REQUEST, user }); // send create user request

  analytics.track('create user');

  return axios.post('/api/user/create', { user }).then(
    () => checkOffline(dispatch, getState),
    () => dispatch({ type: ActionTypes.CREATE_USER_FAIL, error: 'Sorry, no internet connection' }) // create user failed
  );
};

// add profile picture
export const addProfilePicture = (_id, profilePicture) => (dispatch, getState) => {
  return axios.post('/api/user/profilePicture', { _id, profilePicture }).then(
    response => {
      dispatch({ type: ActionTypes.UPDATE_PROFILE_PICTURE_SUCCESS, user: response.data });
      checkOffline(dispatch, getState);
    },
    () => dispatch({ type: ActionTypes.UPDATE_PROFILE_PICTURE_OFFLINE })
  );
};

export const addLife = () => () => {
  return axios.post('api/changeLife');
};

// logout user
export const logout = () => ({ type: ActionTypes.LOGOUT });

// load user prompts
export const loadUser = _id => (dispatch, getState) => {
  return axios.post('/api/user', { _id }).then(response => {
    dispatch({ type: ActionTypes.LOAD_USER_SUCCESS, user: response.data });
    checkOffline(dispatch, getState);
  }, () => null); // load user prompts failed
};

// load other user
export const loadOtherUser = _id => (dispatch, getState) => {
  dispatch({ type: ActionTypes.LOAD_OTHER_USER_REQUEST, id: _id });

  return axios.post('/api/user/other', { _id }).then(response => {
    dispatch({ type: ActionTypes.LOAD_OTHER_USER_SUCCESS, user: response.data });
    checkOffline(dispatch, getState);
  }, () => null); // load user prompts failed
};

export const loadUserAnswers = _id => (dispatch, getState) => {
  return axios.post('/api/user/answers', { _id }).then(response => {
    dispatch({ type: ActionTypes.LOAD_USER_ANSWERS_SUCCESS, answers: response.data.answers });
    checkOffline(dispatch, getState);
  }, () => null); // load user prompts failed
};

export const loadCategories = () => (dispatch, getState) => {
  return axios.post('/api/categories').then(response => {
    dispatch({ type: ActionTypes.LOAD_CATEGORIES_SUCCESS, categories: response.data.categories });
    checkOffline(dispatch, getState);
  }, () => null); // load user prompts failed
};

// update streak
export const updateStreak = user => (dispatch, getState) => {
  dispatch({ type: ActionTypes.UPDATE_STREAK });

  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const day = now.getDate();

  return axios.post('/api/user/streak', { user, year, month, day }).then(
    response => {
      dispatch({ type: ActionTypes.UPDATE_STREAK_SUCCESS, user: response.data });
      checkOffline(dispatch, getState);
    },
    () => dispatch({ type: ActionTypes.UPDATE_STREAK_OFFLINE, user })
  );
};

// reset streak
export const resetStreak = user => (dispatch, getState) => {
  dispatch({ type: ActionTypes.RESET_STREAK });

  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const day = now.getDate();

  return axios.post('/api/user/reset', { user_id: user._id, year, month, day }).then(
    response => {
      dispatch({ type: ActionTypes.RESET_STREAK_SUCCESS, user: response.data });
      checkOffline(dispatch, getState);
    },
    () => dispatch({ type: ActionTypes.RESET_STREAK_OFFLINE })
  );
};

export const editUser = user => ({ type: ActionTypes.EDIT_USER, user });

// change edit name
export const changeEditName = name => ({ type: ActionTypes.CHANGE_EDIT_USER_NAME, name });

// change edit email
export const changeEditEmail = email => ({ type: ActionTypes.CHANGE_EDIT_USER_EMAIL, email });

export const saveEditUser = user => dispatch => {
  dispatch({ type: ActionTypes.SAVE_EDIT_USER_REQUEST });

  return axios
    .post('/api/user/update', { user })
    .then(
      () => dispatch({ type: ActionTypes.SAVE_EDIT_USER_SUCCESS, user }),
      () =>
        dispatch({ type: ActionTypes.SAVE_EDIT_USER_FAIL, error: 'Sorry, name or email is taken' })
    );
};

export const resetSeen = () => ({ type: ActionTypes.RESET_SEEN });

export const setCode = (_id, code) => (dispatch, getState) => {
  dispatch({ type: ActionTypes.SET_CODE, code });

  return axios
    .post('/api/user/code', { _id, code })
    .then(() => checkOffline(dispatch, getState), () => null);
};
