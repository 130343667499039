import { formattedDate } from './dateFunctions';

const downloadTxtFile = (text, fileName) => {
  const file = new Blob([text], { type: 'text/plain' });
  const element = document.createElement('a');
  if (element.download !== undefined) {
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    element.style.visibility = 'hidden';
    document.body.appendChild(element);
    element.click();
  }
};

export const download = answers => {
  let text = '';

  answers.forEach(a => {
    text += `${formattedDate(a.answered)}\n`;
    text += `${a.prompt_title}\n`;
    text += `${a.text}\n\n`;
  });

  const fileName = answers.length > 1 ? 'myMüseAnswers.txt' : 'myMüseAnswer.txt';

  downloadTxtFile(text, fileName);
};
