import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { alignments, boxShadow, colors, spaces, widths } from '../constants';
import { padding } from '../functions/styleFunctions';

const styles = ({
  atTop,
  backgroundColor,
  border,
  flexDirection,
  justifyContent,
  zIndex,
  ...props
}) => ({
  content: {
    alignItems: alignments.center,
    display: 'flex',
    flexDirection,
    ...padding(props),
    justifyContent: alignments[justifyContent || 'spaceBetween'],
    margin: '0 auto',
    maxWidth: widths.contain,
    width: '100%'
  },
  header: {
    backgroundColor: atTop && !border ? colors[backgroundColor || 'white'] : colors[backgroundColor || 'white'],
    boxShadow: !atTop || border ? boxShadow : 'none',
    display: 'flex',
    flexShrink: 0,
    width: '100%',
    zIndex: zIndex || 1
  }
});

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      atTop: true
    };

    this.handleBodyScroll = this.handleBodyScroll.bind(this);
  }

  componentDidMount() {
    const { containerId } = this.props;

    if (containerId) {
      const container = document.getElementById(containerId);
      if (container) {
        this.container = container;
        this.container.addEventListener('scroll', this.handleBodyScroll);
      }
    }
  }

  componentWillUnmount() {
    if (this.container) {
      this.container.removeEventListener('scroll', this.handleBodyScroll);
    }
  }

  handleBodyScroll() {
    const { atTop } = this.state;
    if (this.container && this.container.scrollTop > 0 && atTop) {
      this.setState({ atTop: false });
      return;
    }
    if (this.container && this.container.scrollTop <= 0 && !atTop) {
      this.setState({ atTop: true });
    }
  }

  render() {
    const { children, ...props } = this.props;
    const { atTop } = this.state;
    const style = styles({ atTop, ...props });
    return (
      <div className="header" style={style.header}>
        <div className="header-content" style={style.content}>
          {children}
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  containerId: PropTypes.string
};

Header.defaultProps = {
  conntainerId: 'container',
  flexDirection: 'row',
  padding: spaces.medium
};

export default Header;
