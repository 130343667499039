// src/pages/SharedAnswer.js

import PropTypes from 'prop-types';
import moment from 'moment';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as answerActions from '../actions/answerActions';
import * as idActions from '../actions/idActions';
import * as promptActions from '../actions/promptActions';
import * as userActions from '../actions/userActions';
import Button from '../components/Button';
import Box from '../components/Box';
import Card from '../components/Card';
import Icon from '../components/Icon';
import MediaItem from '../components/MediaItem';
import LikeButton from '../components/LikeButton';
import Text from '../components/Text';
import Title from '../components/Title';
import { JOURNAL } from '../constants';
import { formattedToday } from '../functions/dateFunctions';
import ConfirmModal from '../modals/ConfirmModal';
import ShareModal from '../modals/ShareModal';
import Answer from '../objects/Answer';
import analytics from '../functions/analyticsFunctions';

class SharedAnswer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmUnlike: false,
      confirmReport: false,
      showShareModal: false,
    };

    this.handleLoadAnswer = this.handleLoadAnswer.bind(this);
    this.handleLike = this.handleLike.bind(this);
    this.handleNewAnswer = this.handleNewAnswer.bind(this);
    this.handleReport = this.handleReport.bind(this);
    this.handleSetAnswerId = this.handleSetAnswerId.bind(this);
    this.handleUnreport = this.handleUnreport.bind(this);
    this.handleUser = this.handleUser.bind(this);
  }

  handleLoadAnswer(answer) {
    const { history, loadCategory, loadAnswer } = this.props;
    analytics.track('load answer', { from: 'shared answer' });
    loadCategory('Answers');
    loadAnswer(answer);
    this.handleSetAnswerId(answer._id);
    history.push(`/answer/${answer.prompt_id ? answer.prompt_id : `journal/${answer._id}`}`);
  }

  handleNewAnswer(answer) {
    const { answers, createNewAnswer, history, loadAnswer, loadCategory, user } = this.props;

    const isJournal = answer.categories[0] === JOURNAL;

    analytics.track('new answer', { from: 'shared answer' });

    this.handleSetAnswerId(answer._id);

    if (isJournal) {
      if (answers.length) {
        const answerFound = answers.find(a => a.prompt_title === formattedToday());
        if (answerFound) {
          loadAnswer(answerFound);
        }
      }
      loadCategory(JOURNAL);
      history.push('/answer/journal');
      return;
    }

    loadCategory(answer.categories[0]);

    createNewAnswer(
      new Answer(
        user._id,
        user.name,
        answer.prompt_id,
        answer.prompt_title,
        answer.type,
        answer.categories
      )
    );

    history.push(`/answer/${answer.prompt_id}`);
  }

  handleLike(answer) {
    const { user: { _id }, likeAnswer } = this.props;

    analytics.track('like answer', { from: 'shared answer', like: !answer.likers.includes(_id) });

    if (answer.user_id === _id) return; // can't like or unlike your answer

    answer.likers.includes(_id) ? this.setState({ confirmUnlike: true }) : likeAnswer(answer._id, _id);
  }

  handleReport(answer) {
    const { reportAnswer } = this.props;

    reportAnswer(answer._id);
    analytics.track('report answer', { from: 'shared answer' });

    this.setState({ confirmReport: false });
  }

  handleUnreport(answer) {
    const { unreportAnswer } = this.props;

    unreportAnswer(answer._id);
  }

  handleSetAnswerId(answerId) {
    const { id, setUserAnswerId, setWorldAnswerId } = this.props;

    if (id === 'world') setWorldAnswerId(answerId);
    if (id === 'user') setUserAnswerId(answerId);
  }

  handleUser(user_id) {
    const { history, loadOtherUser } = this.props;
    analytics.track('load profile', { from: 'shared answer' });
    loadOtherUser(user_id);
    history.push(`/user/${user_id}`);
  }

  render() {
    const { answer, id, unlikeAnswer, user } = this.props;
    const { confirmReport, confirmUnlike, showShareModal } = this.state;

    const isMyAnswer = answer.user_id === user._id;

    return (
      <Fragment>
        <Card id={`${id}${answer._id}`}>
          {id !== 'user' && (
            <Box alignItems="center" justifyContent="spaceBetween" width="full">
              <MediaItem
                onClick={() => {
                  if (!answer.anonymous) {
                    this.handleUser(answer.user_id);
                    this.handleSetAnswerId(answer._id);
                  }
                }}
                src={!answer.anonymous ? answer.profilePicture : null}
                title={
                  answer.anonymous ? 'Anonymous' : answer.user_name ? answer.user_name : 'Anonymous'
                }
                subTitle={moment(answer.sharedOn).fromNow()}
              />
              {!isMyAnswer && !!user.name && (
                <Button
                  noShadow
                  id="report"
                  onClick={() => answer.reported ? this.handleUnreport(answer) : this.setState({ confirmReport: true })}
                  padding="medium"
                >
                  <Icon color={answer.reported ? 'red' : 'grey'} type="flag" size="small" />
                </Button>
              )}
              <Button
                noShadow
                id="share"
                onClick={() => this.setState({ showShareModal: true })}
                padding="medium"
              >
                <Icon color="darkGrey" type="share" size="small" />
              </Button>
            </Box>
          )}
          <Box alignItems="center" justifyContent="spaceBetween" width="full">
            <Title
              flexShrink={1}
              id="worldPrompt"
              onClick={() =>
                isMyAnswer
                  ? this.handleLoadAnswer(answer)
                  : this.handleNewAnswer(answer)}
              paddingTop={id !== 'user' && 'medium'}
            >
              {answer.prompt_title}
            </Title>
          </Box>
          <Text
            id="worldAnswer"
            onClick={() =>
              isMyAnswer
                ? this.handleLoadAnswer(answer)
                : this.handleNewAnswer(answer)}
            paddingTop="medium"
          >
            {answer.text}
          </Text>
          <Button
            id="like"
            backgroundColor={answer.likers && answer.likers.includes(user._id) ? 'red' : 'white'}
            color={answer.likers && answer.likers.includes(user._id) ? 'white' : 'red'}
            marginTop="large"
            onClick={() => this.handleLike(answer)}
            position="relative"
            width="full"
          >
            {answer.likers && answer.likers.includes(user._id) ? 'Love sent' : 'Send love'}
            <LikeButton
              onClick={() => this.handleLike(answer)}
              liked={answer.likers && answer.likers.includes(user._id)}
              likes={answer.likes}
            />
          </Button>
        </Card>
        {confirmReport && (
          <ConfirmModal
            action="Flag for review"
            handleClose={() => this.setState({ confirmReport: false })}
            onClick={() => this.handleReport(answer)}
          />
        )}
        {confirmUnlike && (
          <ConfirmModal
            action="Take back your love"
            handleClose={() => this.setState({ confirmUnlike: false })}
            onClick={() => unlikeAnswer(answer._id, user._id)}
          />
        )}
        {showShareModal && (
          <ShareModal
            answer={answer}
            noWorld={!isMyAnswer}
            onClose={() => this.setState({ showShareModal: false })}
          />
        )}
      </Fragment>
    );
  }
}

SharedAnswer.propTypes = {
  admin: PropTypes.array.isRequired,
  answer: PropTypes.object.isRequired,
  answers: PropTypes.array.isRequired,
  createNewAnswer: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  id: PropTypes.string,
  likeAnswer: PropTypes.func.isRequired,
  loadAnswer: PropTypes.func.isRequired,
  loadCategory: PropTypes.func.isRequired,
  loadOtherUser: PropTypes.func.isRequired,
  setUserAnswerId: PropTypes.func.isRequired,
  setWorldAnswerId: PropTypes.func.isRequired,
  unlikeAnswer: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = ({ admin, answers, user }) => {
  return { admin, answers, user };
};

SharedAnswer = connect(mapStateToProps, {
  ...answerActions,
  ...idActions,
  ...promptActions,
  ...userActions
})(SharedAnswer);

export default withRouter(SharedAnswer);
