import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const worldReducer = (state = initialState.world, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_SHARED_ANSWERS_REQUEST:
      return { ...state, page: action.page, loading: true };

    case ActionTypes.LOAD_SHARED_ANSWERS_SUCCESS:
      return {
        ...state,
        sharedAnswers:
          action.page === 1
            ? action.sharedAnswers
            : [...state.sharedAnswers, ...action.sharedAnswers],
        loading: action.sharedAnswers.length !== 10,
        page: action.page
      };

    case ActionTypes.SHARE_ANSWER:
      return {
        ...state,
        sharedAnswers: [
          {
            ...action.answer,
            shared: true,
            sharedOn: new Date(),
            anonymous: action.anonymous,
            likes: (action.answer.likes || 0) + 1,
            likers: [...(action.answer.likers || []), action.answer.user_id]
          },
          ...state.sharedAnswers
        ]
      };

    case ActionTypes.UNSHARE_ANSWER: {
      const index = state.sharedAnswers.findIndex(a => a._id === action.answer_id);
      return {
        ...state,
        sharedAnswers: [
          ...state.sharedAnswers.slice(0, index),
          ...state.sharedAnswers.slice(index + 1)
        ]
      };
    }

    case ActionTypes.LIKE_ANSWER: {
      const index = state.sharedAnswers.findIndex(a => a._id === action.answer_id);
      const answer = state.sharedAnswers[index];

      return {
        ...state,
        sharedAnswers: [
          ...state.sharedAnswers.slice(0, index),
          { ...answer, likes: answer.likes + 1, likers: [...answer.likers, action.user_id] },
          ...state.sharedAnswers.slice(index + 1)
        ]
      };
    }

    case ActionTypes.UNLIKE_ANSWER: {
      const index = state.sharedAnswers.findIndex(a => a._id === action.answer_id);
      const answer = state.sharedAnswers[index];

      const likeIndex = answer.likers.findIndex(liker => liker === action.user_id);

      return {
        ...state,
        sharedAnswers: [
          ...state.sharedAnswers.slice(0, index),
          {
            ...answer,
            likes: answer.likes - 1,
            likers: [...answer.likers.slice(0, likeIndex), ...answer.likers.slice(likeIndex + 1)]
          },
          ...state.sharedAnswers.slice(index + 1)
        ]
      };
    }

    case ActionTypes.REPORT_ANSWER: {
      const index = state.sharedAnswers.findIndex(a => a._id === action.answer_id);
      const answer = state.sharedAnswers[index];

      return {
        ...state,
        sharedAnswers: [
          ...state.sharedAnswers.slice(0, index),
          { ...answer, reported: true },
          ...state.sharedAnswers.slice(index + 1)
        ]
      };
    }

    case ActionTypes.UNREPORT_ANSWER: {
      const index = state.sharedAnswers.findIndex(a => a._id === action.answer_id);
      const answer = state.sharedAnswers[index];

      return {
        ...state,
        sharedAnswers: [
          ...state.sharedAnswers.slice(0, index),
          { ...answer, reported: false },
          ...state.sharedAnswers.slice(index + 1)
        ]
      };
    }

    default:
      return state;
  }
};

export default worldReducer;
