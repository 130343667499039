// src/pages/HomePage.js

import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import * as answerActions from '../actions/answerActions';
import * as promptActions from '../actions/promptActions';
import * as userActions from '../actions/userActions';
import Box from '../components/Box';
import Button from '../components/Button';
import Container from '../components/Container';
import EmptyMessage from '../components/EmptyMessage';
import Header from '../components/Header';
import HeaderButton from '../components/HeaderButton';
import Icon from '../components/Icon';
import Page from '../components/Page';
import SharedAnswer from '../components/SharedAnswer';
import SideMenuButton from '../components/SideMenuButton';
import Status from '../components/Status';
import Text from '../components/Text';
import Title from '../components/Title';
import analytics from '../functions/analyticsFunctions';
import { isDesktop } from '../functions/deviceFunctions';

const styles = {
  ios: {
    width: 'auto',
    minHeight: '50px',
    maxHeight: '50px',
    height: '50px',
    flexShrink: 0
  },
  img: {
    flexShrink: 0,
    width: '200px',
    height: 'auto'
  }
};

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasMounted: false,
    };
  }

  componentDidMount() {
    this.props.loadTodayPrompt();

    this.setState({ hasMounted: true });
  }

  render() {
    const { prompts, sharedAnswers, todayPrompt } = this.props;
    const { hasMounted } = this.state;

    const prompt = prompts.find(p => p._id === todayPrompt);

    return (
      <Page>
        <Status />
        <Header containerId="home" zIndex={2}>
          <SideMenuButton />
          <HeaderButton
            backgroundColor="purple"
            color="white"
            id="startHeader"
            to="/"
            onClick={() => analytics.track('start musing', { from: 'home header' })}
            paddingX="xxLarge"
          >
            Start müsing
          </HeaderButton>
        </Header>
        <Container
          alignItems="center"
          id="home"
          itemScope
          itemType="http://schema.org/Organization"
          padding="medium"
        >
          <Title
            color="purple"
            fontSize="56px"
            itemProp="name"
            paddingBottom="medium"
            paddingTop="xxxLarge"
          >
            Müse
          </Title>
          <Title fontSize="32px" paddingBottom="medium" textAlign="center">
            A space to reflect and learn about yourself
          </Title>
          <Text color="darkGrey" paddingBottom="large" textAlign="center">
            \myüz\ v. to think about something carefully and thoroughly
          </Text>
          {!hasMounted ? null : isDesktop() ? (
            <Button
              backgroundColor="purple"
              color="white"
              id="start1"
              marginBottom="xxLarge"
              onClick={() => analytics.track('start musing', { from: 'home' })}
              to="/"
              padding="large xxLarge"
            >
              Start müsing
            </Button>
          ) : (
            <Button
              marginBottom="xLarge"
              marginTop="large"
              padding="large xxLarge"
              href="https://apps.apple.com/us/app/m%C3%BCse-daily-reflection-journal/id1237483776"
              itemProp="url"
              onClick={() => analytics.track('world', { from: 'home' })}
              target="_blank"
            >
              <img
                alt="iOS app"
                id="ios"
                src="https://twos.s3.amazonaws.com/appStore.svg"
                style={styles.ios}
                title="iOS app"
              />
            </Button>
          )}
          <Box
            className="mobile-column"
            justifyContent="spaceBetween"
            noOverflow
            paddingBottom="large"
            paddingTop="xLarge"
            width="full"
          >
            <Box
              backgroundColor="white"
              borderRadius="round"
              boxShadow
              margin="medium"
              noOverflow
              width="200px"
            >
              <img alt="müse" style={styles.img} src="https://twos.s3.amazonaws.com/muse1.png" />
            </Box>
            <Box
              alignItems="center"
              flexDirection="column"
              flexGrow="1"
              flexShrink="1"
              justifyContent="center"
              height="100%"
              padding="medium"
            >
              <Title color="purple" fontSize="32px" paddingBottom="medium">
                Why?
              </Title>
              <Title component="h1" itemProp="description" noBreak textAlign="center">
                Reflecting helps you increase awareness, make better decisions, and create a
                better life for yourself and the world around you
              </Title>
            </Box>
          </Box>
          <Box
            className="mobile-column"
            justifyContent="spaceBetween"
            noOverflow
            paddingBottom="large"
            width="full"
          >
            <Box
              backgroundColor="white"
              borderRadius="round"
              boxShadow
              margin="medium"
              noOverflow
              width="200px"
            >
              <img alt="müse" style={styles.img} src="https://twos.s3.amazonaws.com/muse2.png" />
            </Box>
            <Box
              alignItems="center"
              flexDirection="column"
              flexGrow="1"
              flexShrink="1"
              justifyContent="center"
              height="100%"
              padding="medium"
            >
              <Title color="purple" fontSize="32px" paddingBottom="medium">
                What?
              </Title>
              <Title
                component="h1"
                lineHeight="1.5"
                itemProp="description"
                noBreak
                textAlign="center"
              >
                An app to help you reflect on deep questions and big ideas
              </Title>
            </Box>
          </Box>
          <Box
            className="mobile-column"
            justifyContent="spaceBetween"
            noOverflow
            paddingBottom="large"
            width="full"
          >
            <Box
              backgroundColor="white"
              borderRadius="round"
              boxShadow
              margin="medium"
              noOverflow
              width="200px"
            >
              <img alt="müse" style={styles.img} src="https://twos.s3.amazonaws.com/muse3.png" />
            </Box>
            <Box
              alignItems="center"
              flexDirection="column"
              flexGrow="1"
              flexShrink="1"
              justifyContent="center"
              height="100%"
              padding="medium"
            >
              <Title color="purple" fontSize="32px" paddingBottom="medium">
                How?
              </Title>
              <Title component="h1" itemProp="description" noBreak textAlign="center">
                Reflect daily to discover what motivates you, how to overcome fear, and how to do
                more of what you love
              </Title>
            </Box>
          </Box>
          <Button
            backgroundColor="purple"
            color="white"
            id="start2"
            marginY="xLarge"
            to="/"
            padding="large xxLarge"
            onClick={() => analytics.track('start musing', { from: 'home', second: true })}
          >
            Start müsing
          </Button>
          {prompt && (
            <Fragment>
              <Title color="purple" fontSize="32px" paddingTop="xLarge">
                Your daily prompt
              </Title>
              <Title paddingBottom="large" textAlign="center" paddingTop="small">
                {prompt.title}
              </Title>
              <Button
                color="purple"
                id="today"
                marginBottom="xLarge"
                to="/answer/today"
                padding="large xxLarge"
                onClick={() => analytics.track('today', { from: 'home' })}
              >
                Start müsing
              </Button>
            </Fragment>
          )}
          <Title
            color="purple"
            fontSize="32px"
            paddingTop="xLarge"
            textAlign="center"
          >
            The world is müsing...
          </Title>
          <Box flexDirection="column" paddingTop="medium">
            {sharedAnswers.length > 0 ? (
              sharedAnswers
                .slice(0, 3)
                .map(answer => <SharedAnswer answer={answer} key={answer._id} />)
            ) : (
              <EmptyMessage>Loading...</EmptyMessage>
            )}
          </Box>
          <Button
            backgroundColor="purple"
            color="white"
            id="world"
            marginBottom="xLarge"
            to="/world"
            padding="large xxLarge"
            onClick={() => analytics.track('world', { from: 'home' })}
          >
            See the world Müse
          </Button>
          <Title color="purple" fontSize="32px" paddingTop="xLarge">
            iOS app
          </Title>
          <Button
            marginBottom="xLarge"
            marginTop="large"
            padding="large xxLarge"
            href="https://apps.apple.com/us/app/m%C3%BCse-daily-reflection-journal/id1237483776"
            itemProp="url"
            target="_blank"
            onClick={() => analytics.track('iOS app', { from: 'home' })}
          >
            <img
              alt="iOS app"
              id="ios"
              src="https://twos.s3.amazonaws.com/appStore.svg"
              style={styles.ios}
              title="iOS app"
            />
          </Button>
          <Title color="purple" fontSize="32px" paddingTop="xLarge">
            Social
          </Title>
          <Box marginBottom="xLarge" marginTop="large">
            <Button
              id="followTwitter"
              href="https://twitter.com/reflectwithmuse"
              target="_blank"
              marginRight="medium"
              padding="large"
              onClick={() => analytics.track('twitter', { from: 'home' })}
            >
              <Icon color="purple" type="twitter" />
            </Button>
            <Button
              id="followInstagram"
              href="https://www.instagram.com/reflectwithmuse/"
              target="_blank"
              marginX="medium"
              padding="large"
              onClick={() => analytics.track('instagram', { from: 'home' })}
            >
              <Icon color="purple" type="instagram" />
            </Button>
            <Button
              id="followFacebook"
              href="https://www.facebook.com/reflectwithmuse/"
              target="_blank"
              marginLeft="medium"
              padding="large"
              onClick={() => analytics.track('facebook', { from: 'home' })}
            >
              <Icon color="purple" type="facebook" />
            </Button>
          </Box>
          <Title color="purple" fontSize="32px" paddingTop="xLarge">
            Contact
          </Title>
          <Text paddingY="large" textAlign="center">
            We love stories, perspectives, and questions
          </Text>
          <Button
            color="purple"
            id="contact"
            itemProp="email"
            marginBottom="xLarge"
            href="mailto:hi@reflectwithmuse.com?subject=Hi Müse"
            target="_blank"
            padding="large xxLarge"
            onClick={() => analytics.track('contact us', { from: 'home' })}
          >
            Email
          </Button>
          <Title color="purple" fontSize="32px" paddingTop="xLarge">
            Jobs
          </Title>
          <Text paddingY="large" textAlign="center">
            Interested in learning while helping build Müse?
          </Text>
          <Button
            color="purple"
            id="job"
            itemProp="email"
            marginBottom="xLarge"
            href="mailto:hi@reflectwithmuse.com?subject=Job"
            target="_blank"
            padding="large xxLarge"
            onClick={() => analytics.track('job', { from: 'home' })}
          >
            Email
          </Button>
          <Title color="purple" fontSize="32px" marginTop="xLarge" paddingBottom="large">
            Start müsing
          </Title>
          <Button
            backgroundColor="purple"
            color="white"
            id="start3"
            marginBottom="xLarge"
            to="/"
            padding="large xxLarge"
            onClick={() => analytics.track('start musing', { from: 'home', third: true })}
          >
            Start müsing
          </Button>
          <Title
            color="purple"
            fontSize="32px"
            paddingTop="xLarge"
            paddingBottom="xxxLarge"
            textAlign="center"
          >
            Thank you for müsing
          </Title>
        </Container>
      </Page>
    );
  }
}

HomePage.propTypes = {
  loadTodayPrompt: PropTypes.func.isRequired,
  prompts: PropTypes.array.isRequired,
  sharedAnswers: PropTypes.array.isRequired,
  todayPrompt: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = ({ prompts, todayPrompt, user, world }) => ({
  prompts,
  sharedAnswers: world.sharedAnswers,
  todayPrompt,
  user
});

export default connect(mapStateToProps, {
  ...answerActions,
  ...promptActions,
  ...userActions
})(HomePage);
