import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const dataReducer = (state = initialState.data, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_ADMIN_ANSWERS_SUCCESS:
      return {
        ...state,
        answers: action.answers
      };

    case ActionTypes.LOAD_ADMIN_PROMPTS_SUCCESS:
      return {
        ...state,
        prompts: action.prompts
      };

    case ActionTypes.LOAD_ADMIN_USERS_SUCCESS:
      return {
        ...state,
        users: action.users
      };

    case ActionTypes.CLEAR_DATA:
      return initialState.data;

    default:
      return state;
  }
};

export default dataReducer;
