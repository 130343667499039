import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import * as sideMenuActions from '../actions/sideMenuActions';
import HeaderButton from './HeaderButton';
import Icon from './Icon';

const SideMenuButton = ({ toggleSideMenu }) => (
  <HeaderButton id="sideMenu" onClick={toggleSideMenu}>
    <Icon type="menu" />
  </HeaderButton>
);

SideMenuButton.propTypes = {
  toggleSideMenu: PropTypes.func
};

export default connect(null, { ...sideMenuActions })(SideMenuButton);
