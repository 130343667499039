import PropTypes from 'prop-types';
import React from 'react';

import { colors } from '../constants';

const Page = ({ children }) => <div style={styles.page}>{children}</div>;

Page.propTypes = {
  children: PropTypes.any.isRequired
};

const styles = {
  page: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    backgroundColor: colors.white
  }
};

export default Page;
