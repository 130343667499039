import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const editPromptReducer = (state = initialState.editPrompt, action) => {
  switch (action.type) {
    case ActionTypes.EDIT_PROMPT:
      return action.prompt;

    case ActionTypes.CLEAR_EDIT_PROMPT:
      return initialState.editPrompt;

    case ActionTypes.CHANGE_EDIT_PROMPT_TITLE:
      return { ...state, title: action.title };

    case ActionTypes.PICK_EDIT_PROMPT_CATEGORY:
      const categories = state.categories.slice();
      const category = action.category;
      const categoryIndex = categories.indexOf(category);
      categoryIndex === -1 ? categories.push(category) : categories.splice(categoryIndex, 1);
      return { ...state, categories: categories };

    default:
      return state;
  }
};

export default editPromptReducer;
