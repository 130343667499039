// src/pages/WorldPage.js

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { loadSharedAnswers } from '../actions/answerActions';
import { setWorldAnswerId } from '../actions/idActions';
import BackButton from '../components/BackButton';
import Page from '../components/Page';
import Header from '../components/Header';
import HeaderButton from '../components/HeaderButton';
import HeaderTitle from '../components/HeaderTitle';
import Container from '../components/Container';
import EmptyMessage from '../components/EmptyMessage';
import SharedAnswer from '../components/SharedAnswer';

class WorldPage extends Component {
  constructor(props) {
    super(props);

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    const { ids: { worldAnswerId }, loadSharedAnswers } = this.props;

    const answers = document.getElementById('worldAnswers');

    this.world = answers;

    this.world.addEventListener('scroll', this.handleScroll);

    if (worldAnswerId) {
      const answer = document.getElementById(`world${worldAnswerId}`);
      if (answer) answer.scrollIntoView();
    } else {
      loadSharedAnswers(1);
    }
  }

  componentWillUnmount() {
    this.world && this.world.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const { world, loadSharedAnswers } = this.props;
    const { page, loading } = world;

    // if we found body
    if (this.world) {
      // clientHeight is height of body
      // scrollHeight is height of entire scroll
      // scroll top is how much we have scrolled
      const { clientHeight, scrollHeight, scrollTop } = this.world;

      // scroll offset is how much we can scroll minus how much we have scrolled
      const scrollOffset = scrollHeight - scrollTop;

      // we have scrolled to the bottom
      if (scrollOffset - clientHeight <= scrollHeight / 10) {
        !loading && loadSharedAnswers(page + 1);
      }
    }
  }

  render() {
    const { setWorldAnswerId, world } = this.props;
    const { sharedAnswers, loading } = world;

    return (
      <Page>
        <Header containerId="worldAnswers">
          <BackButton onClick={() => setWorldAnswerId('')} />
          <HeaderTitle>Müse world</HeaderTitle>
          <HeaderButton hide />
        </Header>
        <Container id="worldAnswers" padding="medium">
          {sharedAnswers.length > 0 ? (
            sharedAnswers.map(answer => (
              <SharedAnswer answer={answer} id="world" key={answer._id} />
            ))
          ) : (
            <EmptyMessage>Loading...</EmptyMessage>
          )}
          {sharedAnswers.length > 0 &&
            loading && <EmptyMessage paddingY="medium">Loading...</EmptyMessage>}
        </Container>
      </Page>
    );
  }
}

WorldPage.propTypes = {
  ids: PropTypes.object.isRequired,
  loadSharedAnswers: PropTypes.func.isRequired,
  setWorldAnswerId: PropTypes.func.isRequired,
  world: PropTypes.object.isRequired
};

const mapStateToProps = ({ ids, world }) => {
  return { ids, world };
};

WorldPage = connect(mapStateToProps, { loadSharedAnswers, setWorldAnswerId })(WorldPage);

export default WorldPage;
