import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

import HeaderButton from './HeaderButton';
import Icon from './Icon';

const BackButton = ({ history, onClick, ...props }) => (
  <HeaderButton
    className="back-button"
    onClick={() => {
      onClick && onClick();
      history.goBack();
    }}
    {...props}
  >
    <Icon type="back" />
  </HeaderButton>
);

BackButton.propTypes = {
  history: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

BackButton.defaultProps = {
  onClick: null
};

export default withRouter(BackButton);
