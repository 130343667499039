import initialState from './initialState';
import ActionTypes from '../actions/actionTypes';

const idsReducer = (state = initialState.ids, action) => {
  switch (action.type) {
    case ActionTypes.SET_ANSWER_ID:
      return {
        ...state,
        answerId: action.answerId
      };

    case ActionTypes.SET_USER_ANSWER_ID:
      return {
        ...state,
        userAnswerId: action.userAnswerId
      };

    case ActionTypes.SET_WORLD_ANSWER_ID:
      return {
        ...state,
        worldAnswerId: action.worldAnswerId
      };

    default:
      return state;
  }
};

export default idsReducer;
