import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from '../components/Button';
import Header from '../components/Header';
import HeaderButton from '../components/HeaderButton';
import HeaderTitle from '../components/HeaderTitle';
import Icon from '../components/Icon';
import Modal from '../components/Modal';
import Text from '../components/Text';
import analytics from '../functions/analyticsFunctions';

const museText = 'Reflect with Müse';
const museUrl = `%0D%0A%0D%0A${museText}%0D%0A%0D%0Ahttps://apps.apple.com/us/app/m%C3%BCse-daily-reflection-journal/id1237483776`;

class ShareMenuModal extends Component {
  constructor(props) {
    super(props);

    this.handleShareEmail = this.handleShareEmail.bind(this);
    this.handleShareFacebook = this.handleShareFacebook.bind(this);
    this.handleShareLinkedin = this.handleShareLinkedin.bind(this);
    this.handleShareTwitter = this.handleShareTwitter.bind(this);
  }

  handleShareEmail() {
    const { handleClick, shareText } = this.props;

    analytics.track('share email', { from: 'share modal' });

    window.open(
      `mailto:?subject=${museText}&body=${shareText}${museUrl}`,
      '_blank'
    );

    handleClick();
  }

  handleShareLinkedin() {
    const { handleClick, shareText } = this.props;

    analytics.track('share linkedin', { from: 'share modal' });

    window.open(
      `https://www.linkedin.com/shareArticle?mini=true&url=https://www.reflectwithmuse.com&title=${museText}&summary=${shareText}${museUrl}&source=`,
      '_blank'
    );

    handleClick();
  }

  handleShareTwitter() {
    const { handleClick, shareText } = this.props;

    analytics.track('share twitter', { from: 'share modal' });

    window.open(
      `http://twitter.com/share?text=${shareText}${museUrl}`,
      '_blank'
    );

    handleClick();
  }

  handleShareFacebook() {
    const { handleClick, shareText } = this.props;

    analytics.track('share facebook', { from: 'share modal' });

    window.open(`http://www.facebook.com/sharer.php?s=100&p[title]=${museText}&p[summary]=${shareText}&p[url]=www.reflectwithmuse.com`, '_blank');

    handleClick();
  }

  render() {
    const { handleClose } = this.props;

    return (
      <Modal onClick={handleClose} padding="medium">
        <Header padding="none">
          <HeaderButton left onClick={handleClose}>
            <Icon type="back" />
          </HeaderButton>
          <HeaderTitle fs="ml">Share to...</HeaderTitle>
          <HeaderButton hide />
        </Header>
        <Button marginTop="medium" onClick={this.handleShareEmail} width="full">
          <Text paddingRight="small">Email</Text>
          <Icon size="small" type="email" />
        </Button>
        <Button marginTop="medium" onClick={this.handleShareTwitter} width="full">
          <Text paddingRight="small">Twitter</Text>
          <Icon size="small" type="twitter" />
        </Button>
        <Button marginTop="medium" onClick={this.handleShareLinkedin} width="full">
          <Text paddingRight="small">LinkedIn</Text>
          <Icon size="small" type="linkedin" />
        </Button>
        <Button marginTop="medium" onClick={this.handleShareFacebook} width="full">
          <Text paddingRight="small">Facebook</Text>
          <Icon size="small" type="facebook" />
        </Button>
      </Modal>
    );
  }
}

ShareMenuModal.propTypes = {
  handleClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  shareText: PropTypes.string,
  user: PropTypes.object.isRequired
};

const mapStateToProps = ({ user }) => ({
  user
});

export default connect(mapStateToProps)(ShareMenuModal);
